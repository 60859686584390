import axios from "./";


const AdminAPI = {
    getLinguists: () => {
        const url = `/batches`
        return axios.get(url)
    },
    getSummaryBatch: (batchID) => {
        const url = `/batches/${batchID}`
        return axios.get(url)
    },
    getMeData: () => {
        const url = `/me`
        return axios.get(url)
    },
    getStats: () => {
        const url = `/batches/stats`
        return axios.get(url)
    }
}

export default AdminAPI