import {createBrowserRouter} from 'react-router-dom'
import SharedRoute from './route-guards/private-route/SharedRoute'
import PublicLayout from './containers/public-layout/PublicLayout'
import * as routeNames from "./utilities/constants";
import * as pages from './pages'
import PublicRoute from "./route-guards/public-route/PublicRoute";
import PrivateLayout from "./containers/private-layout/PrivateLayout";
import PrivateRoute from "./route-guards/shared-route/PrivateRoute";

const router = createBrowserRouter(
    [
        {
            element: <PrivateRoute/>,
            children: [
                {
                    element: <PrivateLayout/>,
                    children: [
                        {
                            exact: true,
                            path: routeNames.ROUTE_HOME,
                            element: <pages.Home/>,
                        },
                        {
                            exact: true,
                            path: routeNames.ROUTE_ABBREVIATIONS,
                            children: [
                                {
                                    exact: true,
                                    index: true,
                                    element: <pages.Summary/>,
                                },
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_ABBREVIATIONS_EDIT,
                                    element: <pages.AbbreviationEdit/>,
                                },
                            ]
                        },

                        //isAdmin
                        {
                            exact: true,
                            path: routeNames.ROUTE_DASHBOARD,
                            element: <pages.Dashboard/>,
                        },

                        {
                            exact: true,
                            path: routeNames.ROUTE_LINGUISTS,
                            children: [
                                {
                                    exact: true,
                                    index: true,
                                    element: <pages.Linguists/>,
                                },
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_LINGUIST,
                                    children: [
                                        {
                                            exact: true,
                                            index: true,
                                            element: <pages.LinguistBatches/>,
                                        },
                                        {
                                            exact: true,
                                            path: routeNames.ROUTE_BATCH_ID,
                                            element: <pages.Batch/>,
                                        },
                                    ]
                                },

                            ]

                        },


                        // QA
                        {
                            exact: true,
                            path: routeNames.ROUTE_REVIEW,
                            children: [
                                {
                                    exact: true,
                                    index: true,
                                    element: <pages.Review/>,
                                },
                                {
                                    exact: true,
                                    path: routeNames.ROUTE_SUMMARY_REVIEW,
                                    children: [
                                        {
                                            exact: true,
                                            index: true,
                                            element: <pages.SummaryReview/>,
                                        },
                                        {
                                            exact: true,
                                            path: routeNames.ROUTE_EDIT_REVIEW,
                                            element: <pages.EditReview/>,
                                        },
                                    ]
                                },
                            ]
                        },
                    ],
                },
            ],
        },
        {
            element: <PublicRoute/>,
            children: [
                {
                    element: <PublicLayout/>,
                    children: [
                        {
                            exact: true,
                            path: routeNames.ROUTE_LOGIN,
                            element: <pages.Login/>,
                        },

                    ],
                },
            ],
        },
        {
            element: <SharedRoute/>,
            children: [
                {
                    path: '*', // Not found route
                    element: <pages.NotFound/>,
                }
            ],
        },
    ],
    {
        basename: '/',
    }
)

export default router
