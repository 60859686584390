export const columnsTableDashboard = [
    {
        id: 1,
        title: 'ID',
        keyName: 'id',
    },
    {
        id: 2,
        title: 'Name',
        keyName: 'username',
    },
    {
        id: 3,
        title: 'Batches Completed',
        keyName: 'completed_batches',
    },
    {
        id: 4,
        title: 'Abbreviations completed',
        keyName:  'abbreviation_completed_total',
    },
    {
        id: 5,
        title: 'Average Time',
        keyName:  'avg_time',
    },
    {
        id: 6,
        title: 'Batches reviewed by PM',
        keyName: 'reviewed_batches',
    },
]