import React, { useCallback } from 'react'
import useAlert from './useAlert'
import {FAILURE} from "../utilities/constants";

/**
 * Usage:
 *
 * const showError = useError()
 * showError('something wrong')
 *
 * */

const useError = () => {
    const { triggerAlert } = useAlert()

    return useCallback(errorMessage => {
        triggerAlert({ severity: FAILURE, title: errorMessage })
    }, [])
}

export default useError
