import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import PageLayout from "../../containers/page-layout/PageLayout";
import {useNavigate, useParams} from "react-router-dom";
import Table from "../../components/table/Table";
import {columnsTableLinguistBatches} from "./columns-table-linguist-batches/columnsTableLinguistBatches";
import AdminAPI from "../../http-requests/admin.http";
import Spinner from "../../components/spinner/Spinner";
import {handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";

const useStyles = createUseStyles(theme => ({

}))


const LinguistBatches = () => {

    const {linguistID} = useParams();
    const navigate = useNavigate()
    const [batches, setBatches] = useState(null)
    const [linguist, setLinguist] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const showError = useError()
    const navigateToBatch = (id) => {
        navigate(`${id}`)
    }

    useEffect(() => {
        getBatches()
    }, [])

    const getBatches = async () => {
        try {
            setIsLoading(true)
            const {data} = await AdminAPI.getLinguists()
            const linguist = data.find(linguist => linguist.id === parseInt(linguistID))
            setLinguist(linguist)
            setBatches(linguist.batches)
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }

    const classes = useStyles();

    return <>
        {isLoading && <Spinner/>}
        <PageLayout
            pageTitle={`${linguist?.username} batches`}
        >
            <Table
                onClickRow={(id) => navigateToBatch(id)}
                columns={columnsTableLinguistBatches}
                data={batches}
            />
        </PageLayout>
    </>
}

export default LinguistBatches;