export const columnsReview = [
    {
        id: 1,
        title: 'ID',
        keyName: 'id',
    },
    {
        id: 2,
        title: 'Nome del lemma',
        keyName: 'title',
    },
    {
        id: 3,
        title: 'Revisionato',
        keyName: 'is_revised',
    },
    {
        id: 4,
        title: '',
        keyName: 'edit_document',
    },
]