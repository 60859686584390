import {useCallback, useEffect, useMemo} from 'react';
import {createUseStyles} from "react-jss";
import useAbbreviations from "../../hooks/useAbbreviations";
import {ABBREVIATION_MODEL} from "../../pages/mockData";
import {useLocation} from "react-router-dom";
import Tooltip from "../tooltip/Tooltip";
import {ROUTE_ABBREVIATIONS} from "../../utilities/constants";

const useStyles = createUseStyles(theme => ({
    footer: {
        gridRowStart: 'footer',
        gridRowEnd: 'footer',
        gridColumnStart: 1,
        gridColumnEnd: 6,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 300px',
        alignItems: 'center',
        padding: [16, 12],
        backgroundColor: 'rgba(48,48,48, 0.3)',
        [theme.mediaQueries.xxlUp]: {
            maxHeight: 60,
            padding: [12, 24],
        }
    },

    progress: {
        height: 20,
        width: 300,
        borderRadius: 20,
        '&::-webkit-progress-bar': {
            backgroundColor: theme.palette.text['quaternary'],
            borderRadius: 20,
        },
        '&::-webkit-progress-value': {
            backgroundColor: theme.palette.green['middle'],
            borderRadius: 20,
        },
        '&::-moz-progress-bar': {
            backgroundColor: theme.palette.green['middle'],
            borderRadius: 20,
        },
    },

    containerProgress: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,

    },

    nextAbbreviation: {
        fontWeight: 700,
        fontSize: 16,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: 400,
    },

    batchId:{
        fontWeight: 700,
        fontSize: 16,
    },

    counter: {
        fontSize: 20,
        fontWeight: 700,
        color: theme.palette.common['dark'],
    }
}))


const Footer = () => {

    const {pathname} = useLocation()
    const idAbbreviation = pathname.split('/').pop()
    const location = useLocation()

    const {abbreviationsSet} = useAbbreviations()

    const pendingAbbreviations = useMemo(
        () => abbreviationsSet.allIds.filter(abbrID => abbreviationsSet.byId[abbrID][ABBREVIATION_MODEL.status] === 'in_progress'),
        [abbreviationsSet]
    )
    const completedAbbreviations = useMemo(
        () => abbreviationsSet.allIds.filter( abbrID => abbreviationsSet.byId[abbrID][ABBREVIATION_MODEL.status] === 'completed'),
        [abbreviationsSet]
    )
    const currentObject = abbreviationsSet?.allIds?.length ?  {...abbreviationsSet.byId[pendingAbbreviations[0]]} : null


    const onKeyDownHandler = useCallback(
        (e) => {
            switch (e.keyCode) {
                case 81:
                    if (e.ctrlKey) {
                        e.preventDefault()
                        searchForTitle( !!currentObject?.title ? currentObject?.title : abbreviationsSet.byId[idAbbreviation]?.title )
                    }
                    break;
                default:
                    break;
            }
        },
        [currentObject, abbreviationsSet]
    )

    useEffect(
        () => {
            document.addEventListener('keydown', onKeyDownHandler)
            return () => {
                document.removeEventListener('keydown', onKeyDownHandler)
            }
        },
        [onKeyDownHandler]
    )
    const searchForTitle = (title) => {
        const url = `https://www.treccani.it/enciclopedia/ricerca/${title}`
        window.open(url, '_blank')
    }

    const classes = useStyles();

    return <>
        <footer
            className={classes.footer}>
            <span className={classes.nextAbbreviation} onClick={() => searchForTitle( !!currentObject?.title ? currentObject?.title : abbreviationsSet.byId[idAbbreviation]?.title )}>
                <Tooltip
                    top={'-60px'}
                    content={'Press "CTRL + Q" to search on Treccani.'}
                >
                {!!currentObject?.title ? currentObject?.title : abbreviationsSet.byId[idAbbreviation]?.title}
                </Tooltip>
                {location.pathname !== ROUTE_ABBREVIATIONS &&
                    <span className={classes.batchId}>
                    Batch ID: #{!!currentObject?.batch_id ? currentObject?.batch_id : abbreviationsSet.byId[idAbbreviation]?.batch_id}
                </span>}
            </span>
            <div className={classes.containerProgress}>
                <progress
                    value={completedAbbreviations?.length}
                    max={abbreviationsSet.allIds?.length}
                    className={classes.progress}
                />
                <span className={classes.counter}>{completedAbbreviations?.length} of { abbreviationsSet.allIds?.length}</span>
            </div>
        </footer>
    </>
}

export default Footer;