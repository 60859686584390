import {createUseStyles} from "react-jss";
import Menu from "../menu/Menu";
import {
    ROUTE_ABBREVIATIONS,
    ROUTE_DASHBOARD, ROUTE_EDIT_REVIEW,
    ROUTE_HOME,
    ROUTE_LINGUISTS,
    ROUTE_LOGIN, ROUTE_REVIEW
} from "../../utilities/constants";
import {TranslatedLogo, TreccaniLogo} from "../../assets/icons";
import {useLocation} from "react-router-dom";
import useUser from "../../hooks/useUser";
import {useMemo} from "react";

const useStyles = createUseStyles(theme => ({
    headerRoot: {
        gridRowStart: 'header',
        gridRowEnd: 'header',
        padding: '16px 56px',
        gridColumnStart: 1,
        gridColumnEnd: 6,
    },

    headerMenu: {
        display: 'flex',
        gap: '20px',
        alignItems: 'center',
    },

    containerLogo: {
        display: 'flex',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

const Header = () => {
    const location = useLocation();
    const isLogin = location.pathname !== ROUTE_LOGIN;
    const {user} = useUser()


    const renderMenu = useMemo(() => {
        switch (true) {
            case !!user?.is_admin:
                return [
                    {
                        to: ROUTE_DASHBOARD,
                        title: 'Dashboard',
                    },
                    {
                        to: ROUTE_LINGUISTS,
                        title: 'Review Linguists',
                    }
                ]
            case !!user?.is_qa:
                return [
                    {
                        to: ROUTE_REVIEW,
                        title: 'Revisione'
                    }
                ]

            default:
                return [
                    {
                        to: ROUTE_HOME,
                        title: 'Home'
                    },
                    {
                        to: ROUTE_ABBREVIATIONS,
                        title: 'Summary'
                    },
                ]
        }
    } , [user])

    const classes = useStyles();
    return <>
        <div className={classes.headerRoot}>

            <div className={classes.headerMenu}>
                <div className={classes.containerLogo}>
                    <TranslatedLogo
                        width={150}
                        height={50}
                    />
                    <TreccaniLogo
                        width={150}
                        height={50}
                    />
                </div>
                {isLogin && <Menu items={renderMenu}/>}
            </div>

        </div>
    </>
}

export default Header;