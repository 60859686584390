import React from 'react';
import {createUseStyles, useTheme} from "react-jss";
import {checkIfIsSup, formatTime} from "../../utilities/helpers";
import {Link, NavLink} from "react-router-dom";
import {
    ArrowIcon, CheckedIcon,
    CloseIcon,
    DoneIcon,
    EditIcon,
    NotCheckedIcon,
} from "../../assets/icons";
import Tooltip from "../tooltip/Tooltip";

const useStyles = createUseStyles(theme => ({
    root: {
        borderCollapse: 'collapse',
        minWidth: 880,
        marginTop: 20,
    },

    head: {
        '& th': {
            padding: 8,
            backgroundColor: 'rgba(0, 71, 154, 0.30)',
            fontWeight: 700,
            fontSize: 16,
            '&:first-child': {
                borderTopLeftRadius: 8,
            },
            '&:last-child': {
                borderTopRightRadius: 8,
            }
        }
    },

    body: {
        '& td': {
            cursor: ({isClickable}) => isClickable ? 'pointer' : 'default',
            padding: 12,
            backgroundColor: theme.palette.common.white,
        },
        '& tr:hover': {
            backgroundColor: 'rgba(0, 71, 154, 0.30)',
        },
        '& tr:nth-child(even)': {
            backgroundColor: 'rgba(0, 71, 154, 0.10)',
        },
        '& tr:nth-child(odd)': {
            backgroundColor: 'rgba(0, 71, 154, 0.05)',
        },
    },

    actionsButton: {
        display: 'flex',
        justifyContent: 'end',
    },

    tableContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: 400,
    },

    buttonHover: {
        '& a': {
            color: '#000',
            fontWeight: 700,
            fontSize: '16px',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
                textDecorationThickness: '2px',
                textUnderlineOffset: '6px',
            },
        }
    },

    // Pagination
    paginationContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: '1fr',
        justifyItems: 'center',
        marginTop: 16,
        alignItems: 'center',
        '& svg': {
            cursor: 'pointer',
            width: 16,
            height: 16,
        },
        '& svg:first-child': {
            transform: 'rotate(180deg)',
            visibility: ({page}) => page === 1 && 'hidden',
        },
        '& svg:last-child': {
            display: ({page, totalPages}) => page === totalPages && 'none',
        }
    },
    paginationButton: {
        margin: '0 4px',
        padding: '4px 8px',
        backgroundColor: 'rgba(0, 71, 154, 0.30)',
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: 4,
        cursor: 'default',
    },
}))


const Table = ({
                   data,
                   columns,
                   onClickRow,
                   isClickable = false,
                   isPaginated = false,
                   page = 1,
                   getDocumentsData,
                   totalPages,
               }) => {

    const handlePageChange = (page) => {
        getDocumentsData(page);
    };

    const rowClickHandler = (item) => {
        const id = !!item?.id ? item.id : item
        typeof onClickRow === 'function' && onClickRow(id)
    }

    const theme = useTheme()
    const renderCell = (item, column) => {
        switch (column.keyName) {
            case 'avg_time':
                return formatTime(item[column.keyName])

            case 'id':
                return `#${item[column.keyName]}`

            case 'actions':
            case 'goToLinguist':

                return <div className={classes.actionsButton}>
                    <NavLink
                        className={classes.buttonHover}
                        onClick={() => rowClickHandler(item)}
                    >
                        <Link>
                            {column.keyName === 'actions' ? 'Show batch' : 'Show batches'}
                        </Link>
                    </NavLink>
                </div>
            case 'batches':
                return item[column.keyName].length

            case 'batch_name':
                return `Batch ${item.id}`

            case 'reviewer_id':
                return 'To Review'

            case 'abbreviation_completed_total':
            case 'completed_batches':
                const str = item[column.keyName].toString()
                return str.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

            // QA
            case 'edit_document':
            case 'edit_abbreviation':
                return <div className={classes.actionsButton}>
                    <NavLink
                        className={classes.buttonHover}
                        onClick={() => rowClickHandler(item)}
                    >
                        <Link>
                            {column.keyName === 'edit_document' ? 'Modifica' : <EditIcon/>}
                        </Link>
                    </NavLink>
                </div>
            case 'false_abbreviation':
                return item[column.keyName] || item?.confirm_false_abbreviation ?
                    <Tooltip
                        content={'Questa è una falsa abbreviazione'}
                        top={'-190%'}
                    >
                        <DoneIcon width={22} height={22} fill={theme.palette.common.black}/>
                    </Tooltip> : null

            case 'abbreviation':
                return <Tooltip content={item.context} top={'-500%'}>
                    {checkIfIsSup(item[column.keyName])}
                </Tooltip>
            case 'extended_abbreviation':
                return checkIfIsSup(item[column.keyName])
            case 'title':
                return item[column.keyName].length > 50 ? item[column.keyName].slice(0, 58) + '...' : item[column.keyName]
            case 'is_revised':
                return item[column.keyName] ?
                    <DoneIcon width={22} height={22} fill={theme.palette.common.black}/> : null
            default:
                return item[column.keyName]
        }
    }


    const classes = useStyles({isClickable, page, totalPages});

    return <>
        <div className={classes.tableContainer}>
            <table className={classes.root}>
                <thead className={classes.head}>
                <tr>
                    {columns.map(column => (
                        <th key={column.id}>{column.title}</th>
                    ))}
                </tr>
                </thead>


                <tbody className={classes.body}>
                {data?.length ? data?.map((item, index) => {
                    return (
                        <tr
                            key={item.id || index}
                        >
                            {columns.map(column => (
                                <td key={column.id}
                                    style={{minWidth: !!column.width ? column.width : 'auto'}}
                                >
                                    {renderCell(item, column)}
                                </td>
                            ))}
                        </tr>
                    )
                }) : <>
                    <tr>
                        <td colSpan={columns.length} style={{textAlign: 'center'}}>
                            Non ci sono abbreviazioni disponibili
                        </td>
                    </tr>
                </>}
                </tbody>
            </table>
        </div>
        {isPaginated && (
            <div className={classes.paginationContainer}>
                <ArrowIcon
                    onClick={() => handlePageChange(page - 1)}
                />
                <div className={classes.paginationButton}>
                    {page} di {totalPages}
                </div>
                <ArrowIcon
                    onClick={() => handlePageChange(page + 1)}
                />
            </div>
        )}
    </>
}

export default Table;