import React, {useEffect, useState} from 'react';
import useError from "../../hooks/useError";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import QaAPI from "../../http-requests/qa.http";
import {handleApiError} from "../../utilities/helpers";
import Spinner from "../../components/spinner/Spinner";
import Abbreviation from "../home/Abbreviation";
import {ROUTE_REVIEW} from "../../utilities/constants";


const EditReview = () => {
    const [batchData, setBatchData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const showError = useError()
    const {reviewID, lemmaID} = useParams();
    const navigate = useNavigate();
    const {state} = useLocation();

    useEffect(() => {
        getDocumentData();
    }, [])

    const getDocumentData = async () => {
        setIsLoading(true)
        try {
            const {data} = await QaAPI.getDocumentLemmas(reviewID);
            setBatchData(data?.find(item => item.lemma_id === +lemmaID))
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }

    return <>
        {isLoading && <Spinner/>}
        <Abbreviation
            canViewPreviousAbbreviation
            revisedAbbreviation={batchData?.revised_abbreviation}
            isFalseAbbreviation={batchData?.false_abbreviation}
            extendedAbbreviation={batchData?.extended_abbreviation}
            id={batchData?.lemma_id}
            abbreviation={batchData?.abbreviation}
            suggestions={batchData?.suggestions}
            context={batchData?.context}
            updateFromSummary
            canGoBack
            lang={'it'}
            onBackClick={() => navigate(`${ROUTE_REVIEW}/${reviewID}`, {
                state: {
                    page: state?.page,
                    searchValue: state?.searchValue
                }
            })}
        />
    </>
}

export default EditReview;