import {createUseStyles} from 'react-jss'
import {Outlet, useLocation, useParams} from "react-router-dom";
import Header from "../../components/header/Header";
import {toggleAlert} from "../../store/slices/app";
import {useDispatch} from "react-redux";
import Alert from "../../components/alert/Alert";
import AbbreviationProvider from "../../context/AbbreviationProvider";
import Footer from "../../components/footer/Footer";
import useUser from "../../hooks/useUser";
import useAlert from "../../hooks/useAlert";
import {useEffect} from "react";
import AdminAPI from "../../http-requests/admin.http";
import {handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";


const useStyles = createUseStyles((theme) => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: ' 64px 0.02fr 35px 35px 1fr 60px',
        gridTemplateAreas: `
            "header header header header header"
            "title title title title title"
            "context1 context1 context1 context1 context1"
            "context2 context2 context2 context2 context2"
            "content content content content content"
            "footer footer footer footer footer"
        `,
        height: '100%',
        [theme.mediaQueries.xxlUp]: {
            gridTemplateRows: ' 96px 0.04fr 48px 48px 1fr 60px',
        }
    },
    alerts: {
        pointerEvents: 'none',
        position: 'fixed',
        top: 68,
        left: 8,
        right: 8,
        zIndex: 1029,
        [theme.mediaQueries.mUp]: {
            top: 132,
        },
    },

    row: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 6,
    },
    blue: {
        extend: 'row',
        gridRowStart: 'header',
        gridRowEnd: 'context1',
        backgroundColor: theme.palette.background.section,
        minHeight: 200,
        [theme.mediaQueries.xxlUp]: {
            minHeight: 320,
        }
    },
    white: {
        extend: 'row',
        gridRowStart: 'context2',
        gridRowEnd: 'footer',
        backgroundColor: theme.palette.common.light,
    },
}))

const PrivateLayout = () => {

    const dispatch = useDispatch()
    const {user, updateUser, resetUser} = useUser()
    const {isAlertOpen, alertData} = useAlert()
    const location = useLocation()
    const showError = useError()
    const {abbreviationID} = useParams()

    useEffect(() => {
        userMe()
    }, [location.pathname])
    
    const userMe = async () => {
        try {
            const {data} = await AdminAPI.getMeData()
            updateUser(data)
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        }
    }


    const classes = useStyles()

    return <>
        <div className={classes.alerts}>
            {isAlertOpen && (
                <Alert
                    visible={isAlertOpen}
                    variant={alertData.severity}
                    message={alertData.title}
                    dismissTimeout={5000}
                    dismissCallback={() =>
                        dispatch(toggleAlert({visible: false}))
                    }
                />
            )}
        </div>
        <div className={classes.gridContainer}>
            <div className={classes.blue}/>
            <Header/>
            <div className={classes.white}/>
            <AbbreviationProvider>
                <Outlet/>
                {(!user?.is_admin && !user?.is_qa) || abbreviationID ? <Footer/> : null}
            </AbbreviationProvider>
        </div>
    </>
}

export default PrivateLayout
