import {createUseStyles} from "react-jss";
import React, {useEffect, useMemo} from "react";
import Abbreviation from "./Abbreviation";
import useAbbreviations from "../../hooks/useAbbreviations";
import {ABBREVIATION_MODEL} from "../mockData";
import {ROUTE_ABBREVIATIONS, ROUTE_DASHBOARD, ROUTE_REVIEW} from "../../utilities/constants";
import {Navigate, useNavigate} from "react-router-dom";
import Spinner from "../../components/spinner/Spinner";
import PageLayout from "../../containers/page-layout/PageLayout";
import useUser from "../../hooks/useUser";

const useStyles = createUseStyles(theme => ({
    suggestion: {
        display: 'flex',
        opacity: 0.5,
        width: 320,
        height: 48,
        borderLeftWidth: 0,
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgb(0, 0, 0)',
        fontWeight: 700,
        fontSize: 16,
    }
}))


const Home = () => {

    const {abbreviationsSet, isFetching} = useAbbreviations()

    const pendingAbbreviations = useMemo(
        () => abbreviationsSet.allIds.filter(abbrID => abbreviationsSet.byId[abbrID][ABBREVIATION_MODEL.status] === 'in_progress'),
        [abbreviationsSet]
    )

    const {user} = useUser()
    const navigate = useNavigate()
    useEffect(() => {
        !!user?.is_admin && navigate(ROUTE_DASHBOARD)
        !!user?.is_qa && navigate(ROUTE_REVIEW)
    }, [user])

    const classes = useStyles({});

    return isFetching ? <Spinner/> :
        pendingAbbreviations.length ?
            <Abbreviation {...abbreviationsSet.byId[pendingAbbreviations[0]]} />
            :
            abbreviationsSet.allIds.length ? <Navigate to={ROUTE_ABBREVIATIONS}/> : <PageLayout>
                <div className={classes.suggestion}>No abbreviation found</div>
            </PageLayout>

}

export default Home;