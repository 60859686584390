import React from 'react';
import {createUseStyles} from "react-jss";
import {NavLink, Link} from "react-router-dom";
import useUser from "../../hooks/useUser";
import {handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";

const useStyles = createUseStyles(theme => ({

    containerBurgerIcon: {
        marginLeft: 'auto',
        padding: '24px',
        alignSelf: 'start'
    },

    containerButton: {
        display: 'flex',
        padding: '10px',
        marginLeft: 'auto',
        alignItems: 'center',
        gap: '14px',
    },

    containerCloseIcon: {
        marginLeft: 'auto',
        padding: '8px',
        alignSelf: 'start'
    },

    backgroundSidebar: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.72);',
        zIndex: 1
    },

    containerSidebar: {
        display: 'flex',
        padding: '10px',
        flexDirection: 'column',
        backgroundColor: theme.palette.green.dark,
        height: ' 100%',
        maxWidth: 0,
        position: 'fixed',
        zIndex: 2,
        top: 0,
        right: 0,
        overflowX: 'hidden',
        transition: 'width 2s ease',
    },

    containerButtonMobile: {
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 14px',
        gap: '14px',
        marginLeft: 'auto',
        alignItems: 'right',
        textAlign: 'end',
        '& button': {
            '& span': {
                color: 'white',
                padding: 0,
                textAlign: 'right',
                fontSize: '20px',
            }
        }
    },

    buttonHover: {
        '& a': {
            '&:hover': {
                textDecoration: 'underline',
                textDecorationThickness: '2px',
                textUnderlineOffset: '6px',
            },
        }
    }
}))


const Menu = ({items, ...rest}) => {

    let activeStyle = {
        color: '#00000052',
        fontWeight: 700,
        fontSize: '16px',
        cursor: 'pointer',
    };

    let inactiveStyle = {
        color: '#000',
        fontWeight: 700,
        fontSize: '16px',
        cursor: 'pointer',
    }

    const {resetAccessToken, updateUser} = useUser();
    const showError = useError();

    const logout = async () => {
        try {
            updateUser(null);
            resetAccessToken()
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        }
    }

    const classes = useStyles();

    return <div className={classes.containerButton}>
        {items.map(
            ({to, title}) => <NavLink
                className={classes.buttonHover}
                key={to}
                to={to}
                style={({isActive}) =>
                    isActive ? activeStyle : inactiveStyle
                }
            >
                <Link
                    to={to}
                >
                    {title}
                </Link>
            </NavLink>
        )
        }

        <NavLink
            onClick={() => logout()}
            className={classes.buttonHover}
            style={{
                color: '#000',
                fontWeight: 700,
                fontSize: '16px',
                cursor: 'pointer',
            }}
        >
            <Link>
                Logout
            </Link>
        </NavLink>
    </div>
}

export default Menu;