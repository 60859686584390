import colors from './colors'

const neutral = {
    common: colors.common,
    grey: colors.greyscale,
    transparency: colors.transparency,
    gradients: colors.gradients,
}

const palette = {
    light: {
        ...neutral,
        green : {
            main: colors.success["101"],
            dark: colors.success["102"],
            middle: colors.success["103"],
        },
        common: {
            light: colors.common.white,
            middle: colors.greyscale["A300"],
            dark: colors.common.black,
        },
        primary: {
            main: colors.primary[400],
            middle: colors.primary[500],
            dark: 'rgba(34,104,189,0.75)',
            darker: colors.primary[900],
            background: colors.tertiary[800],
            buttonLight: colors.primary[100],
            buttonHover: colors.primary['A15'],
            hover: colors.primary[200],
            extraDark: colors.black[800],
            unsubscribeHover: colors.primary['A05'],
        },
        secondary: {
            main: colors.secondary[500],
            middle: colors.secondary[500],
            dark: colors.secondary[600],
            darker: colors.secondary[800],
            background: colors.gradients['1'],
            backgroundLight: colors.quaternary['A05'],
            buttonLight: colors.secondary[100],
            buttonHover: colors.secondary['A15'],
            hover: colors.secondary[200],
        },
        tertiary: {
            light: colors.quaternary[100],
            main: colors.quaternary[500],
            dark: colors.quaternary[600],
            background: colors.backgrounds.tertiary,
            buttonLight: colors.quaternary[100],
            buttonHover: colors.quaternary['A15'],
            hover: colors.quaternary[200],
        },
        quaternary: {
            light: colors.quinary[100],
            main: colors.quinary[500],
            dark: colors.quinary[600],
            background: colors.gradients['2'],
            backgroundLight: colors.backgrounds.quaternary,
        },
        success: {
            light: colors.success[400],
            main: colors.success[500],
            background: colors.backgrounds.success,
        },
        error: {
            light: colors.error[400],
            main: colors.error[500],
            transparency: colors.error['A75'],
            background: colors.backgrounds.error,
        },
        warning: {
            light: colors.yellow[400],
            main: colors.yellow[500],
            background: colors.backgrounds.warning,
        },
        disabled: {
            light: colors.greyscale[200],
            main: colors.greyscale[400],
            background: colors.greyscale["A400"],
        },
        background: {
            body: colors.greyscale[150],
            section: colors.backgrounds.primary,
            backgroundBlue: colors.backgrounds.blue,
        },
        text: {
            primary: colors.tertiary[800],
            secondary: colors.greyscale[600],
            tertiary: colors.greyscale['A500'],
            quaternary: colors.greyscale['A600'],
            inactive: colors.greyscale[400],
            disabled: colors.greyscale[400],
        },
        link: {
            normal: colors.primary[500],
            visited: colors.tertiary[500],
        },
        icon: {
            active: colors.tertiary[500],
            inactive: colors.greyscale[400],
        },
        action: {
            active: colors.tertiary[500],
            inactive: colors.greyscale[500],
        },
        divider: {
            active: colors.quaternary[500],
            external: colors.greyscale[300],
            internal: colors.greyscale[200],
        },
        input: {
            active: colors.greyscale[700],
            placeholder: colors.greyscale[400],
        },
        label: {
            background: colors.greyscale[100],
            color: colors.greyscale[500],
            borderColor: colors.greyscale[200],
        },
    },
}

export default palette
