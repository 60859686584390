export const columnsTableLinguistBatches = [
    {
        id: 1,
        title: 'ID',
        keyName: 'id',
    },
    {
        id: 2,
        title: 'Name',
        keyName: 'batch_name',
    },
    {
        id: 3,
        title: 'Status',
        keyName: 'reviewer_id',
    },
    {
        id: 4,
        title: '',
        keyName: 'actions',
    }
]