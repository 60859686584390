import {ThemeProvider} from 'react-jss'
import {useSelector} from 'react-redux'
import {getAppTheme} from './theme'
import {RouterProvider} from 'react-router-dom'
import {selectTheme} from './store/slices/app'
import router from "./router";
import UserProvider from "./context/UserProvider";
import AlertProvider from "./context/AlertProvider";

const App = () => {
    const themeName = useSelector(selectTheme)


    return (
        <ThemeProvider theme={getAppTheme({name: themeName})}>
            <AlertProvider>
                <UserProvider>
                    <RouterProvider router={router}/>
                </UserProvider>
            </AlertProvider>
        </ThemeProvider>
    )
}

export default App
