const resetCss = {
    /* Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property */
    '@global *:where(:not(iframe, canvas, img, svg, video):not(svg *))': {
        all: 'unset',
        display: 'revert',
    },
    // eexclude tag sup from reset
    '@global sup': {
        all: 'revert',
    } ,
    /* Preferred box-sizing value */
    '@global *, *::before, *::after': {
        boxSizing: 'border-box',
    },
    /* Remove list styles (bullets/numbers) */
    '@global ol, ul': {
        listStyle: 'none',
    },
    /* For images to not be able to exceed their container */
    '@global img': {
        maxWidth: '100%',
    },
    /* Removes spacing between cells in tables */
    '@global table': {
        borderCollapse: 'collapse',
    },
    /* Revert the 'white-space' property for textarea elements on Safari */
    '@global textarea': {
        whiteSpace: 'revert',
    },
    '@global html, body': {
        scrollBehavior: 'smooth',
        overflowX: 'hidden',
        fontFamily: 'ARS Maquette Pro',
        width: '100%',
        height: '100%',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        fontSmooth: 'never',
    },
    '@global svg[viewBox="0 0 24 24"]:not([data-color])': {},
    '@global h1, h2, h3, p,h4,h5, span, small': {
        'margin-bottom': 0,
    },
    '@global a[data-anchor-unstyled]': {
        'text-decoration': 'none',
    },
    '@global a[data-button]': {
        'text-decoration': 'none',
    },
}

export default resetCss
