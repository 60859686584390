import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import cx from 'classnames'

const useStyles = createUseStyles((theme) => ({
    root: ({
               size,
               variant,
               collapsed,
               icon,
               data,
               disabled,
               width,
               iconPosition,
           }) => ({
        ...theme.utils.flexbox.centered,
        borderRadius: 6,
        position: 'relative',
        backgroundColor: `${theme.palette[data]['dark']}`,
        borderColor: `${theme.palette[data]['main']}`,

        color: variant === 'ghost' ? theme.palette[data].dark : theme.palette.common.light,

        ...(!disabled && {
            cursor: 'pointer',
            '&:hover': {
                backgroundColor:
                    variant === 'ghost' ? 'none' :  theme.palette[data].dark,
                borderColor: theme.palette[data].dark,
                color:
                    variant === 'ghost' ? theme.palette[data].darker : theme.palette.common.light,
                '& svg': {
                    fill: !disabled && (variant === 'ghost') && `${theme.palette[data].darker} !important`,
                },
            },
        }),

        ...(collapsed && {
            height: theme.spacing * 7,
            width: theme.spacing * 7,
        }),
        ...(!collapsed && {
            width: width,
            aspectRatio: 'unset',
            height: theme.spacing * 6,
        }),

        ...(size === 'large' && {
            height: theme.spacing * 7,
        }),
        ...(size === 'medium' && {
            height: theme.spacing * 6,
        }),
        ...(size === 'small' && {
            height: theme.spacing * 5,
        }),

        ...((variant === 'filled') && {
            color: theme.palette.common.light,
        }),

        ...(variant === 'ghost' && {
            backgroundColor: 'unset',
            '&:hover': {
                backgroundColor: theme.palette[data].buttonHover,
            },
        }),


        ...(disabled && {
            backgroundColor: theme.palette.disabled.light,
            borderColor:
                variant === 'filled' ? theme.palette.disabled.light : theme.palette.disabled.main,
            color: theme.palette.disabled.main,
            '&:hover': {
                backgroundColor: theme.palette.disabled.light,
                color: theme.palette.disabled.main,
                borderColor:
                    variant === 'filled' ? theme.palette.disabled.light : theme.palette.disabled.main,
            },
        }),

        '& > span': {
            ...theme.utils.grid.centered,
            whiteSpace: 'nowrap',
            width: '100%',
            textAlign: 'center',
            gridTemplateColumns:
                icon && !collapsed
                    ? `${
                        iconPosition === 'left'
                            ? '24px min-content'
                            : 'min-content 24px'
                    }`
                    : '1fr',
            padding: [0, theme.spacing * 2],
            gridColumnGap: theme.spacing,
            ...(size === 'large' && {
                ...theme.typography.buttonBig,
            }),
            ...(size === 'medium' && {
                ...theme.typography.buttonMedium,
            }),
            ...(size === 'small' && {
                ...theme.typography.buttonSmall,
            }),
            '& svg': {
                ...theme.utils.flexbox.centered,
                width: 22,
                fill: disabled
                    ? `${theme.palette.disabled} !important`
                    : variant !== 'ghost'
                        ? `${theme.palette.common.white} !important`
                        : `${theme.palette[data].main} !important`,
            },
        },
    }),
}))

const Button = ({
                    children,
                    variant = 'filled',
                    type = 'button',
                    collapsed = false,
                    data = 'green',
                    transparency = 'default',
                    alert,
                    icon,
                    iconPosition = 'right',
                    size = 'large',
                    width = 220,
                    height,
                    disabled = false,
                    onClick,
                    className,
                    ...props
                }) => {
    const classes = useStyles({
        size,
        variant,
        collapsed,
        icon,
        data,
        disabled,
        width,
        iconPosition,
    })

    return (
        <button
            className={cx(classes.root, className)}
            {...props}
            type={type}
            onClick={onClick}
            disabled={disabled}
        >
            {collapsed ? (
                <span>{icon}</span>
            ) : icon ? (
                iconPosition === 'left' ? (
                    <span>
                        {icon}
                        <span>{children}</span>
                    </span>
                ) : (
                    <span>
                        <span>{children}</span>
                        {icon}
                    </span>
                )
            ) : (
                <span>{children}</span>
            )}
        </button>
    )
}

export default Button

Button.propTypes = {
    data: PropTypes.oneOf(['common', 'green', 'primary']),
    variant: PropTypes.oneOf(['filled', 'ghost']),
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    iconPosition: PropTypes.oneOf(['left', 'right']),
    transparency: PropTypes.oneOf(['low', 'medium', 'high']),
}
