import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import PageLayout from "../../containers/page-layout/PageLayout";
import Table from "../../components/table/Table";
import {columnsEditReview} from "../edit-review/columnsEditReview";
import Button from "../../components/button/Button";
import useError from "../../hooks/useError";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import QaAPI from "../../http-requests/qa.http";
import {handleApiError} from "../../utilities/helpers";
import Spinner from "../../components/spinner/Spinner";
import {ROUTE_REVIEW, SUCCESS} from "../../utilities/constants";
import useAlert from "../../hooks/useAlert";
import Search from "../../components/search/Search";

const useStyles = createUseStyles(theme => ({
    container: {
        maxWidth: 880,
    },

    buttonContainer: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        borderTop: `1px solid ${theme.palette.primary.main}`,
        padding: [16, 0, 4],
        '& button': {
            justifySelf: 'center'
        }
    },

    documentLength: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.22,
        color: 'rgba(48,48,48,0.7)',
        '& strong': {
            color: theme.palette.primary.main,
            fontWeight: 700,
        }
    },

    link:{
        textDecoration: 'underline',
        cursor: 'pointer'
    }
}))


const SummaryReview = () => {

    const [documentData, setDocumentData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const showError = useError()
    const {reviewID} = useParams();
    const navigate = useNavigate();
    const {triggerAlert} = useAlert()
    const {state} = useLocation();
    const [tableDataFiltered, setTableDataFiltered] = useState(null)
    const [searchValue, setSearchValue] = useState('')


    useEffect(() => {
        getDocumentData();
    }, [])

    const getDocumentData = async () => {
        setIsLoading(true)
        try {
            const {data} = await QaAPI.getDocumentLemmas(reviewID);
            setDocumentData(data);
            setTableDataFiltered(data)
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }

    const goToAbbreviation = (id) => {
        navigate(`edit-lemmas/${id}`, {state: {page: state?.page, searchValue: state?.searchValue}})
    }

    const completeUpdate = async () => {
        setIsLoading(true)
        try {
            await QaAPI.revisedDocument(reviewID);
            navigate(ROUTE_REVIEW, {state: {page: state?.page, searchValue: state?.searchValue}})
            triggerAlert({severity: SUCCESS, title: 'Document revised successfully'})
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }


    const classes = useStyles();

    const pageTitle = !documentData?.[0]?.title ? '' : <p>#{reviewID} - <a
            className={classes.link}
            target={'_blank'}
            rel="noreferrer"
            href={`${process.env.REACT_APP_STATIC_DOCS_ENDPOINT}${documentData?.[0]?.title}`}>
             {documentData?.[0]?.title}
        </a>
    </p>

    return <>
        {isLoading && <Spinner/>}
        <PageLayout
            pageTitle={pageTitle}
            canGoBack
            titleHaveDiffFontSizes
            onBackClick={() => navigate(ROUTE_REVIEW, {state: {page: state?.page, searchValue: state?.searchValue}})}
        >
            <Search
                tableData={documentData}
                setTableDataFiltered={setTableDataFiltered}
                tableDataFiltered={tableDataFiltered}
                placeholder={'Filtra per abbreviazione...'}
                isOnlyFrontSearch
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />

            <Table
                data={tableDataFiltered}
                columns={columnsEditReview}
                onClickRow={(item) => goToAbbreviation(item.lemma_id)}
            />

            <div className={classes.buttonContainer}>
                <Button
                    data={'primary'}
                    onClick={completeUpdate}
                >
                    Concludi revisione
                </Button>
            </div>

            <div className={classes.documentLength}>
                <p>
                    <strong>{documentData?.filter(item => item.revised_abbreviation || item.confirm_false_abbreviation).length} </strong>
                    abbreviazioni revisionate nel documento su
                    <strong> {documentData?.length}</strong> totali
                </p>
            </div>
        </PageLayout>
    </>
}

export default SummaryReview;