import {formatTime} from "../utilities/helpers";

export default (data = {}) => {
    const titles = {
        "linguist_count": "Linguists operating on the system",
        "completed_batches": "Batch completed",
        "total_abbreviations": "Total abbreviations",
        "abbreviation_completed_total": "Completed abbreviations",
        "avg_time": "Average batch completion time for linguist",
        "batches_completed_per_linguist": "Completed batches by linguist",
        "reviewed_batches": "Total reviewed batches",
        "assigned_batch_total": "Total assigned batches",
    }

    const valueToReturn = (key, value) => {
        switch (key) {
            case "avg_time":
                return formatTime(value)
            case "batches_completed_per_linguist":
                const v = value.toFixed(1)
                return v.replace(/.\d+$/, '')
            case "total_abbreviations":
            case "abbreviation_completed_total":
            case "linguist_count":
            case "completed_batches":
            case "reviewed_batches":
            case "assigned_batch_total":
                const str = value.toString()
                return str.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            default:
                return value
        }
    }
    let array = []
    for (const key in data) {
        array.push(
            {
                "id": array.length + 1,
                "title": titles[key] || key,
                "value": valueToReturn(key, data[key])
            }
        )
    }

    return array
}
