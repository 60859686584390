import axios from "./";


const QaAPI = {
    getDocuments: (params) => {
        const url = '/documents';
        return axios.get(url, {params});
    },

    getDocumentLemmas: (documentId) => {
        const url = `/documents/${documentId}/lemmas`;
        return axios.get(url);
    },

    updateLemma: (lemmaId, falseAbbreviation = false, extendedAbbreviation = null, openAISuggestion = false) => {
        const url = `/lemmas/${lemmaId}/revised`;
        return axios.patch(url, {extended_abbreviation: extendedAbbreviation, false_abbreviation: falseAbbreviation, extended_by_openai_suggestion: openAISuggestion});
    },

    revisedDocument: (documentId) => {
        const url = `/documents/${documentId}/revised`;
        return axios.patch(url);
    }
}

export default QaAPI