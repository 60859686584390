import React from 'react';
import {createUseStyles} from "react-jss";
import cx from "classnames";

const useStyles = createUseStyles(theme => ({

    root: {
        width: ({size}) => size,
        height: 'auto',
    },

    center: {
        position: ({overlayFullscreen}) => overlayFullscreen ? 'fixed' : 'absolute',
        left: '50%',
        marginLeft: ({size}) => -size / 2,
        top: '50%',
        marginTop: ({size}) => -size / 2,
    },

    overlay: {
        zIndex: 13,
        position: ({overlayFullscreen}) => overlayFullscreen ? 'fixed' : 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },

    ldsSpinner: {
        zIndex: 13,
        display: 'inline-block',
        position: 'relative',
        width: ({size}) => size,
        height: ({size}) => size,
        '& div': {
            transformOrigin: ({transform}) => transform,
            animation: '$ldsSpinner 1.2s linear infinite',
        },
        '& div:after': {
            color: ({color}) => color,
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 15,
            left: 37,
            width: 6,
            height: 14,
            borderRadius: '40%',
            background: '#000',
        },
        '& div:nth-child(1)': {
            transform: 'rotate(0deg)',
            animationDelay: '-1.1s',

        },
        '& div:nth-child(2)': {
            transform: 'rotate(30deg)',
            animationDelay: '-1s',
        },
        '& div:nth-child(3)': {
            transform: 'rotate(60deg)',
            animationDelay: '-0.9s',
        },
        '& div:nth-child(4)': {
            transform: 'rotate(90deg)',
            animationDelay: '-0.8s',
        },
        '& div:nth-child(5)': {
            transform: 'rotate(120deg)',
            animationDelay: '-0.7s',
        },
        '& div:nth-child(6)': {
            transform: 'rotate(150deg)',
            animationDelay: '-0.6s',
        },
        '& div:nth-child(7)': {
            transform: 'rotate(180deg)',
            animationDelay: '-0.5s',
        },
        '& div:nth-child(8)': {
            transform: 'rotate(210deg)',
            animationDelay: '-0.4s',
        },
        '& div:nth-child(9)': {
            transform: 'rotate(240deg)',
            animationDelay: '-0.3s',
        },
        '& div:nth-child(10)': {
            transform: 'rotate(270deg)',
            animationDelay: '-0.2s',
        },
        '& div:nth-child(11)': {
            transform: 'rotate(300deg)',
            animationDelay: '-0.1s',
        },
        '& div:nth-child(12)': {
            transform: 'rotate(330deg)',
            animationDelay: '0s',
        }
    },

    '@keyframes ldsSpinner': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        }
    },


}))


const Spinner = ({
                     className,
                     size = 100,
                     color = '#000',
                     strokeLineCap = 'square',
                     overlay = true,
                     overlayFullscreen = false,
                     center = true,
                     transform = '40px 40px',
                     ...props
                 }) => {
    const classes = useStyles({size, overlayFullscreen, color, transform})
    const divs = new Array(12).fill(null)
    return <>
        {overlay && <div className={classes.overlay}/>}
        <div className={cx(classes.root, center && classes.center, className)}>
            <div className={classes.ldsSpinner}>
                {divs.map((_, index) => <div key={index}/>)}
            </div>
        </div>
    </>
}

export default Spinner;