import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
    suggestion: {
        display: 'flex',
        border: `1px solid`,
        borderColor: '#CCD4DE',
        borderRadius: 2,
        opacity: ({isSelected}) => isSelected ? 1 : 0.5,
        width: 320,
        height: 48,
        borderLeftWidth: 0,
        cursor: 'pointer',
        transform: ({isSelected}) => isSelected ? `scale(1.1)` : `scale(1)`,
        '&:hover': {
            transform: 'scale(1.1)',
            opacity: 1,
        },
        [theme.mediaQueries.xxlUp]: {
            width: 356,
            height: 56,
        }
    },
    quality: {
        border: `3px solid`,
        borderColor: ({qualityColor}) => qualityColor,
        borderBottomLeftRadius: 2,
        borderTopLeftRadius: 2,
        margin: '-1px 0'
    },
    title: {
        flexGrow: 1,
        fontSize: ({maxTitleLength}) => maxTitleLength ? 16 : 20,
        fontWeight: 500,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.mediaQueries.xxlUp]: {
            fontSize: 40,
        }
    }
}))

const qualityColorMap = {
    1 : '#2FB177',
    2 : '#FF8A00',
    3 : '#ff0034',

}

const Suggestion = ({title, isSelected, quality = 2, onClick}) => {

    const maxTitleLength = !!title.length && title.length > 20

    const classes = useStyles({isSelected, qualityColor: qualityColorMap[quality], maxTitleLength});

    return <div className={classes.suggestion} onClick={onClick}>
        <span className={classes.quality}/>
        <span className={classes.title}>{title}</span>
    </div>
}

export default Suggestion;