import {createUseStyles} from "react-jss";
import useAbbreviations from "../../hooks/useAbbreviations";
import {ABBREVIATION_MODEL} from "../mockData";
import PageLayout from "../../containers/page-layout/PageLayout";
import Spinner from "../../components/spinner/Spinner";
import {EditIcon, PressToChangeIcon} from "../../assets/icons";
import {ROUTE_ABBREVIATIONS, ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_REVIEW} from "../../utilities/constants";
import Button from "../../components/button/Button";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import AbbreviationAPI from "../../http-requests/abbreviation.http";
import {checkIfIsSup, handleApiError} from "../../utilities/helpers";
import useUser from "../../hooks/useUser";
import useError from "../../hooks/useError";

const useStyles = createUseStyles(theme => ({

    context: {
        gridRowStart: 'content',
        gridRowEnd: 'content',
        gridColumnStart: 1,
        gridColumnEnd: 6,
        display: 'flex',
        justifyContent: 'center',
        padding: [10, 0, 16, 0],
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            width: 7,
        },
        '&::-webkit-scrollbar-thumb': {
            height: 40,
            borderRadius: 8,
        },
        '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.5)',
            '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
        }
    },

    suggestions: {
        display: 'grid',
        gridTemplateColumns: '1fr 20px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 6,
        maxWidth: 880,
    },

    title: {
        flexGrow: 1,
        fontSize: 22,
        fontWeight: 500,
        textAlign: 'left',
        display: 'grid',
        gridTemplateColumns: 'minmax(100px, auto) auto minmax(400px, auto)',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 16
    },

    icon: {
        transform: 'rotate(270deg)'
    },

    buttonContainer: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        borderTop: `1px solid ${theme.palette.primary.main}`,
        padding: [16, 0, 4]
    },

    button: {
        gridArea: ' 9 / 3 / 10 / 4',
        justifySelf: 'end',
        width: '100%'
    },

    containerMessage:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        backgroundColor: '#2FB177',
        color: '#fff',
        padding: 10,
        textAlign: 'center',
        borderRadius: 8,
    },
}))

const Summary = () => {
    const {abbreviationsSet, isFetching} = useAbbreviations()
    const navigate = useNavigate()
    const [isComplete, setIsComplete] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const showError = useError()

    const {user} = useUser()
    useEffect(() => {
        !!user?.is_admin && navigate(ROUTE_DASHBOARD)
        !!user?.is_qa && navigate(ROUTE_REVIEW)
    }, [user])


    const isPending = useMemo(() => {
        return abbreviationsSet.allIds.some((abbrId) => {
            const abbreviation = abbreviationsSet.byId[abbrId]
            return abbreviation[ABBREVIATION_MODEL.status] === 'in_progress'
        })
    }, [abbreviationsSet.allIds, abbreviationsSet.byId])

    const goToAbbreviation = (id) => {
        navigate(`${ROUTE_ABBREVIATIONS}/${id}`)
    }

    const complete = async () => {
        setIsLoading(true)
        try {
            const batchId = abbreviationsSet.byId[abbreviationsSet.allIds[0]]
            await AbbreviationAPI.finalConfirmAbbreviation(batchId.batch_id)
            await AbbreviationAPI.finalizeBatch()
            setIsComplete(true)
            navigate(ROUTE_HOME)
            window.location.reload()
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
            setIsLoading(false)
        }
    }

    const classes = useStyles({});

    return isFetching || isLoading ? <Spinner/>
        : !!abbreviationsSet.allIds.length && <PageLayout isNotScrollable pageTitle={'Summary'}>
        {isComplete && <div className={classes.containerMessage}>
            <span>Completed</span>
        </div>}
        <div className={classes.context}>
            <div className={classes.suggestions}>
                {abbreviationsSet.allIds.map((abbrId) => {
                    const abbreviation = abbreviationsSet.byId[abbrId]
                    return <>
                        <div>
                            <div className={classes.title}>
                                <span>{checkIfIsSup(abbreviation[ABBREVIATION_MODEL.title])}</span>
                                <div className={classes.icon}>
                                    <PressToChangeIcon
                                        isOnlyArrow={false}
                                    />
                                </div>
                                <span>
                                    {abbreviation[ABBREVIATION_MODEL.extension]?.replace(/<\/?[^>]+(>|$)/g, "")}
                                </span>
                            </div>
                        </div>
                        <EditIcon
                            onClick={() => goToAbbreviation(abbreviation[ABBREVIATION_MODEL.id])}
                            style={{
                                cursor: 'pointer',
                                visibility: !!abbreviation[ABBREVIATION_MODEL.extension] || !!abbreviation.false_abbreviation ? 'visible' : 'hidden'
                            }}
                        />
                    </>
                })}
            </div>
        </div>
        <div className={classes.buttonContainer}>
            <Button
                onClick={complete}
                disabled={isPending}
                data={'primary'}
                style={{justifySelf: 'center'}}
            >Done</Button>
        </div>
    </PageLayout>
}

export default Summary;