import {createUseStyles} from "react-jss";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Suggestion from "./Suggestion";
import SuggestionsIcons from "../../components/suggestions-icons/SuggestionsIcons";
import PageLayout from "../../containers/page-layout/PageLayout";
import useAbbreviations from "../../hooks/useAbbreviations";
import AbbreviationAPI from "../../http-requests/abbreviation.http";
import {ABBREVIATION_MODEL} from "../mockData";
import {useLocation, useNavigate} from "react-router-dom";
import {ROUTE_ABBREVIATIONS, ROUTE_HOME} from "../../utilities/constants";
import useUser from "../../hooks/useUser";
import {checkIfIsSup, handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";
import QaAPI from "../../http-requests/qa.http";

const useStyles = createUseStyles(theme => ({
    rowContainer: {
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center'
    },

    icons: {
        position: 'absolute',
        right: -339,
        display: 'flex',
    },


    inputContainer: {
        display: 'grid',
        gridTemplateColumns: '116px 316px 116px',
        gap: 96,
        alignItems: 'center',
        paddingTop: ({contextLength}) => contextLength ? 30 : 0,
        [theme.mediaQueries.xxlUp]: {
            gridTemplateColumns: '116px 362px 116px',
        }
    },

    input: {
        borderRadius: 8,
        fontSize: 32,
        fontWeight: 500,
        maxHeight: 64,
        textAlign: 'center',
        borderColor: ({inputError}) => inputError && '#ff0034 !important',
        '&:focus': {
            borderColor: theme.palette.primary.main,
        },
    },

    containerSkip: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 30,
        '& span': {
            color: theme.palette.error.main,
        }
    },

    strong: {
        fontWeight: 700,
        color: theme.palette.primary.main,
    },

    revised: {
        color: 'rgba(0, 0, 0, 0.3)',
        '& strong': {
            color: '#00479a82',
        }
    }
}))


const Abbreviation = ({
                          id,
                          title,
                          abbreviation,
                          suggestions,
                          context,
                          onCompleted,
                          is_name,
                          updateFromSummary = false,
                          canGoBack = false,
                          onBackClick,
                          extendedAbbreviation,
                          canViewPreviousAbbreviation = false,
                          isFalseAbbreviation = false,
                          revisedAbbreviation,
                          lang,
                      }) => {
    const {updateAbbreviation, abbreviationsSet} = useAbbreviations()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [manualInput, setManualInput] = useState('')
    const [inputError, setInputError] = useState(null)
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0)
    const inputRef = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()
    const isHome = location.pathname === ROUTE_HOME
    const {user} = useUser()
    const isAdmin = user?.is_admin
    const showError = useError()

    const onKeyDownHandler = useCallback(
        (e) => {
            if (isSubmitting) return
            switch (e.keyCode) {
                case 40:
                    // down arrow
                    setSelectedSuggestionIndex(prevIndex => {
                        if (prevIndex < suggestions.length - 1 && prevIndex >= 0) return prevIndex + 1
                        return prevIndex
                    })
                    break;
                case 38:
                    // up arrow
                    setSelectedSuggestionIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : prevIndex)
                    break;
                case 9:
                    // tab
                    e.preventDefault()
                    e.stopPropagation()
                    setSelectedSuggestionIndex(-1)
                    inputRef.current.focus()
                    break;
                case 27:
                    // escape
                    // remove focus
                    inputRef.current.blur()
                    setManualInput('')
                    setSelectedSuggestionIndex(0)
                    setInputError(null)
                    break;
                case 13:
                    // spacebar
                    submitExtension()
                    break
                case 32:
                    // return
                    submitExtension(true)
                    break
                case 70:
                    // if ctrl + f
                    if (e.ctrlKey) {
                        e.preventDefault()
                        e.stopPropagation()
                        const regexLastWord = new RegExp(`\\W+(${abbreviation})\\W+(\\w+)`, 'gm');
                        const foundMatchesLastWord = [...context?.matchAll(regexLastWord)]
                        const entireName = foundMatchesLastWord[0]?.[0]
                        const url = !!is_name ? `https://www.google.com/search?q="${entireName}"` : `https://www.treccani.it/enciclopedia/ricerca/${abbreviation}`
                        window.open(url, '_blank')
                    }
                    break
                // if ctrl + a
                case 65:
                    if (e.ctrlKey) {
                        e.preventDefault()
                        e.stopPropagation()
                        const url = `https://www.google.com/search?q=treccani.it+${context}`
                        window.open(url, '_blank')
                    }
                    break
                default:
                    break;
            }
        },
        [id, manualInput, selectedSuggestionIndex]
    )

    useEffect(
        () => {
            document.addEventListener('keydown', onKeyDownHandler)
            return () => {
                document.removeEventListener('keydown', onKeyDownHandler)
            }
        },
        [onKeyDownHandler]
    )

    useEffect(
        () => {
            setSelectedSuggestionIndex(0)
            setManualInput('')
            inputRef.current.blur()
        },
        [id]
    )

    const onChangeHandler = e => {
        setManualInput(e.target.value)
    }

    const errorString = lang === 'en' ? 'Please, write a suggestion or skip it if this is a "False abbreviation"' : 'Inserisci una modifica o marca come "falsa abbreviazione"'
    const submitExtension = async (skip = false) => {
        setIsSubmitting(true)
        try {
            setInputError(null)
            if (manualInput.length >= 0 && skip && (inputRef.current === document.activeElement)) return

            const extension = skip ? null : (manualInput || suggestions[selectedSuggestionIndex]?.title)

            if (!extension && !skip) {
                setInputError(errorString)
                return
            }
            const isOpenAiSuggestion = suggestions ? suggestions[selectedSuggestionIndex]?.priority === 2 : false
            if (!updateFromSummary) {
                await AbbreviationAPI.sendAbbreviationSet(id, skip, extension, isOpenAiSuggestion)
            } else {
                await QaAPI.updateLemma(id, skip, extension, isOpenAiSuggestion)
            }
            // Update context
            updateAbbreviation({
                id, data: {
                    ...abbreviationsSet.byId[id],
                    [ABBREVIATION_MODEL.status]: 'completed',
                    [ABBREVIATION_MODEL.extension]: extension,
                    [ABBREVIATION_MODEL.falseAbbreviation]: skip
                }
            })
            // Navigate
            if (!isHome) {
                if (!isAdmin && !user?.is_qa) {
                    navigate(ROUTE_ABBREVIATIONS)
                } else if (!!onBackClick) {
                    onBackClick()
                } else {
                    navigate(-1)
                }
            }
            // External callback
            onCompleted && onCompleted()
        } catch (err) {
            handleApiError(err, {handleGeneralError: showError})
        } finally {
            setIsSubmitting(false)
        }
    }

    const onSuggestionClickHandler = (index) => {
        // todo: maybe just go to next one
        setSelectedSuggestionIndex(index)
        setManualInput('')
        inputRef.current.blur()
    }


    useEffect(
        () => {
            if (manualInput) {
                setInputError(null)
            }

            if (manualInput.length > 0 && !manualInput.match(/[a-zA-Z0-9]/)) {
                setManualInput(manualInput.trim())
                setInputError(errorString)
            }
        },
        [manualInput]
    )

    const sameWord = useMemo(() => {
        const isSup = abbreviation?.includes('<sup' || '</sup>' || '<sup>')
        const cardinalNumber = abbreviation?.match(/\d+[a-z]/g)


        const regex = new RegExp(`\\W+(${abbreviation})\\W+`, 'gm');
        const foundMatches = context ? [...context?.matchAll(regex)] : []
        const takeMatches = !!foundMatches.length ? foundMatches.flatMap(match => match[1])[0] : context?.split(' ').map(word => {
            return word.length > 1 ? word.replace(/(\(|\)|,|\'|\")/g, '') : word
        }).find(word => word === abbreviation)

        switch (true) {
            case isSup:
                const containsNumber = abbreviation?.match(/\d+/g)
                const firstNumber = containsNumber[0]
                const lastNumber = containsNumber[containsNumber.length - 1]
                const boldWord = `<span style="color: #000">${firstNumber}<sup>${lastNumber}</sup></span>`
                const newContext = context.replace(takeMatches, boldWord)
                return <div dangerouslySetInnerHTML={{__html: newContext}}/>
            case cardinalNumber:
                const number = cardinalNumber[0].match(/\d+/g)
                const supTag = `<span style="color: #000">${number}<sup>°</sup></span>`
                const newContextReplace = context.replace(takeMatches, supTag)
                return <div dangerouslySetInnerHTML={{__html: newContextReplace}}/>
            default:
                if (takeMatches) {
                    const boldWord = `<span style="color: #000">${takeMatches}</span>`
                    const newContext = context.replace(takeMatches, boldWord)
                    return <div dangerouslySetInnerHTML={{__html: newContext}}/>
                } else {
                    return <div>{context}</div>
                }
        }
    }, [abbreviation, context])


    const contextLength = useMemo(() => context?.length > 150, [context])

    const isNotAString = typeof suggestions !== 'string' && suggestions?.length > 0
    const classes = useStyles({inputError, contextLength});

    return <>
        <PageLayout
            pageTitle={checkIfIsSup(abbreviation)}
            subTitle={sameWord}
            canGoBack={canGoBack}
            onBackClick={onBackClick}
        >
            {isNotAString && suggestions?.map(({id, title, priority}, index) => {
                return <div
                    key={id}
                    className={classes.rowContainer}
                >
                    <Suggestion
                        title={title}
                        quality={priority}
                        isSelected={index === selectedSuggestionIndex}
                        onClick={() => onSuggestionClickHandler(index)}
                    />
                    <div className={classes.icons}>
                        {index === selectedSuggestionIndex && <SuggestionsIcons
                            suggestions={suggestions}
                            selectedSuggestionIndex={selectedSuggestionIndex}
                            iconsToShow={1}
                            lang={lang}
                        />}
                    </div>
                </div>
            })}

            {(extendedAbbreviation && canViewPreviousAbbreviation) &&
                <p className={revisedAbbreviation && classes.revised}>Precedentemente estesa come
                    <strong className={classes.strong}> "{extendedAbbreviation}" </strong>
                </p>
            }

            {(isFalseAbbreviation && canViewPreviousAbbreviation) ?
                <p className={revisedAbbreviation && classes.revised}>
                    Precedentemente marcata come <strong className={classes.strong}> falsa abbreviazione </strong>
                </p> : null
            }

            {(revisedAbbreviation && canViewPreviousAbbreviation) &&
                <p>Modificata come
                    <strong className={classes.strong}> "{revisedAbbreviation}" </strong>
                </p>
            }
            <div className={classes.inputContainer}>
                <SuggestionsIcons
                    iconsToShow={2}
                    manualInput={manualInput}
                    inputHasFocus={inputRef.current === document.activeElement}
                    lang={lang}
                />
                <input onChange={onChangeHandler}
                       className={classes.input}
                       value={manualInput}
                       ref={inputRef}
                       onClick={() => setSelectedSuggestionIndex(-1)}
                       onBlur={() => setSelectedSuggestionIndex(0)}
                       lang={lang}
                />
                <SuggestionsIcons
                    iconsToShow={3}
                    manualInput={manualInput}
                    lang={lang}
                />
            </div>
            <div className={classes.containerSkip}>
                {inputError && <span>{inputError}</span>}
                <SuggestionsIcons
                    iconsToShow={4}
                    lang={lang}
                />
            </div>
        </PageLayout>
    </>
}

export default Abbreviation;