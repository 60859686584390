import {createUseStyles} from 'react-jss'
import Tooltip from "../../components/tooltip/Tooltip";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    ROUTE_ABBREVIATIONS,
    ROUTE_DASHBOARD,
    ROUTE_HOME, ROUTE_LINGUISTS, ROUTE_LOGIN
} from "../../utilities/constants";
import useUser from "../../hooks/useUser";
import React, {useEffect, useMemo} from "react";
import {BackIcon} from "../../assets/icons";


const useStyles = createUseStyles((theme) => ({
    title: {
        display: 'grid',
        gridRowStart: 'title',
        gridRowEnd: 'title',
        gridColumnStart: 1,
        gridColumnEnd: 6,
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: ({titleHaveDiffFontSizes}) => titleHaveDiffFontSizes ? 36 : 64,
        fontWeight: 500,
        lineHeight: 1.22,
        padding: [8, 0],
        [theme.mediaQueries.xxlUp]: {
            fontSize:  ({titleHaveDiffFontSizes}) => titleHaveDiffFontSizes ? 50 : 96,
        }
    },
    context: {
        display: 'grid',
        gridRowStart: 'context1',
        gridRowEnd: 'context2',
        gridColumnStart: 1,
        gridColumnEnd: 6,
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgba(48,48,48,0.5)'
    },
    contextPaper: {
        backgroundColor: '#D9E1E8',
        padding: [16, 24],
        borderRadius: 4,
        maxWidth: 560,
        [theme.mediaQueries.xxlUp]: {
            padding: [24, 32]
        }
    },
    suggestionsContainer: {
        gridRowStart: 'content',
        gridRowEnd: 'content',
        gridColumnStart: 1,
        gridColumnEnd: 6,
        display: 'flex',
        justifyContent: 'center',
        padding: [24, 0, 16, 0],
        [theme.mediaQueries.xxlUp]: {
            padding: [48, 0, 32, 0],
        },
        overflow: ({isSummaryPage}) => isSummaryPage && 'hidden',
    },
    suggestions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 16,
        minWidth: 700,
        maxWidth: 700,
        [theme.mediaQueries.xxlUp]: {
            gap: 28
        }
    },

    containerBackIcon: {
        display: 'grid',
        gridRowStart: 'context1',
        gridRowEnd: 'context2',
        gridColumnStart: 1,
        gridColumnEnd: 1,
        alignItems: 'center',
        '& svg': {
            width: 26,
            height: 26,
            fill: theme.palette.common.light,
        }
    },

    backIcon:{
        cursor: 'pointer',
        backgroundColor: theme.palette.background.backgroundBlue,
        zIndex: 1,
        width: 'fit-content',
        textAlign: 'center',
        gap: 8,
        padding: [8, 16],
        display: 'flex',
        justifyContent: 'flex-start',
        borderBottomRightRadius: 8,
        borderTopRightRadius: 8,
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        '& p': {
            fontSize: 14,
            fontWeight: 500,
            textDecoration: 'underline',
            color: theme.palette.common.light,
        },
    }
}))

const PageLayout = ({
                        pageTitle,
                        subTitle,
                        children,
                        canGoBack = false,
                        onBackClick,
                        titleHaveDiffFontSizes = false
                    }) => {

    const location = useLocation()
    const {user} = useUser()
    const navigate = useNavigate()
    const {abbreviationID, batchID} = useParams()
    const isSummaryPage = !!pageTitle && location.pathname.includes(batchID) || location.pathname === ROUTE_ABBREVIATIONS


    useEffect(() => {
        if (
            !!user?.assigned_batch_id &&
            location.pathname !== ROUTE_DASHBOARD &&
            !abbreviationID &&
            location.pathname !== ROUTE_LOGIN
        ) {
            navigate(`${ROUTE_LINGUISTS}/${user?.assigned_linguist_id}/${user.assigned_batch_id}`)
        }
    }, [user])


    const checkIfCanViewTooltip = useMemo(() => {
        return location.pathname.includes('edit-lemmas') || location.pathname === ROUTE_HOME
    }, [location.pathname])

    const classes = useStyles({isSummaryPage, titleHaveDiffFontSizes})

    return <>
        <div className={classes.title}>
            {checkIfCanViewTooltip
                ? <Tooltip
                    top={'-55%'}
                    content={'Press "CTRL + F" to search on Google for abbreviations.'}
                >
                    {pageTitle}
                </Tooltip>
                : pageTitle
            }
        </div>
        {canGoBack && <div className={classes.containerBackIcon}>
            <div className={classes.backIcon}  onClick={onBackClick}>
                <BackIcon/>
                <p>Torna indietro</p>
            </div>
        </div>}
        {subTitle && <div className={classes.context}>
            <Tooltip
                top={'-60%'}
                content={'Press "CTRL + A" to search on Google for context.'}
            >
                <div className={classes.contextPaper}>
                    {subTitle}
                </div>
            </Tooltip>
        </div>}
        <main className={classes.suggestionsContainer}>
            <div className={classes.suggestions}>
                {children}
            </div>
        </main>
    </>
}

export default PageLayout
