import axios from "./";


const AbbreviationAPI = {
    getAbbreviationSet: () => {
        const url = `/lemmas`
        return axios.get(url)
    },
    sendAbbreviationSet: (lemmaID, falseAbbreviation = false, extendedAbbreviation = null, openAISuggestion = false) => {
        const url = `/lemmas/${lemmaID}`
        return axios.patch(url, {extended_abbreviation: extendedAbbreviation, false_abbreviation: falseAbbreviation, extended_by_openai_suggestion: openAISuggestion})
    },
    finalizeBatch: () => {
        const url = `/improve-model`
        return axios.get(url)
    },
    finalConfirmAbbreviation: (lemmaID) => {
        const url = `/batches/${lemmaID}`
        return axios.patch(url)
    },
    login: (params) => {
        const url = `/login`
        return axios.post(url, params)
    }
}

export default AbbreviationAPI