import {Navigate, useNavigate, useParams} from "react-router-dom";
import Abbreviation from "../home/Abbreviation";
import {ROUTE_DASHBOARD, ROUTE_NOT_FOUND, ROUTE_REVIEW} from "../../utilities/constants";
import useAbbreviations from "../../hooks/useAbbreviations";
import Spinner from "../../components/spinner/Spinner";
import useUser from "../../hooks/useUser";
import {useEffect} from "react";

const AbbreviationEdit = () => {
    const {abbreviationsSet, isFetching} = useAbbreviations()
    const {abbreviationID} = useParams()
    const abbreviation = abbreviationsSet.byId[abbreviationID]

    const onEditCompleted = () => {}

    const {user} = useUser()
    const navigate = useNavigate()


    useEffect(() => {
        !!user?.is_qa && navigate(ROUTE_REVIEW)
    }, [user])


    return isFetching ? <Spinner/> :
        abbreviation ? <Abbreviation onCompleted={onEditCompleted} {...abbreviation} /> : <Navigate to={ROUTE_NOT_FOUND}/>
}

export default AbbreviationEdit;