export const columnsEditReview = [
    {
        id: 1,
        title: 'ID',
        keyName: 'lemma_id',
    },
    {
        id: 2,
        title: 'Abbreviazione',
        keyName: 'abbreviation',
    },
    {
        id: 3,
        title: 'Abbreviazione Estesa',
        keyName: 'extended_abbreviation',
    },
    {
        id: 4,
        title: 'Falsa Abbreviazione',
        keyName: 'false_abbreviation',
    },
    {
        id: 5,
        title: 'Abbreviazione Modificata',
        keyName: 'revised_abbreviation',
    },
    {
        id: 6,
        title: '',
        keyName: 'edit_abbreviation',
    }
]