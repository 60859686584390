import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import {
    PressEditIcon,
    PressToAcceptIcon,
    PressToChangeIcon,
    PressToGoBackIcon,
    PressToSkipIcon
} from "../../assets/icons";

const useStyles = createUseStyles(theme => ({
    containerIcons: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 16,
        '& p': {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.text['tertiary'],
        },
        '& strong': {
            fontWeight: 800
        }
    },

    arrowsIcon: {
        display: 'flex',
        gap: 6,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& > *:first-child': {
            transform: ({isFirstIndex}) => isFirstIndex ? 'none' : 'rotate(180deg)'
        }
    },

    acceptIcon: {
        display: 'flex',
        gap: 6,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        '& p': {
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.text['tertiary'],
        },
        '& strong': {
            fontWeight: 800
        }
    }
}))


const SuggestionsIcons = ({
                              selectedSuggestionIndex,
                              suggestions,
                              iconsToShow,
                              manualInput,
                              inputHasFocus,
                              lang = 'en'
                          }) => {
    const isFirstIndex = selectedSuggestionIndex === 0;
    const isLastIndex = selectedSuggestionIndex === suggestions?.length - 1;
    const classes = useStyles({isFirstIndex});

    switch (iconsToShow) {
        case 1:
            return <>
                <div className={classes.containerIcons}>
                    {suggestions?.length > 1 ? <div className={classes.arrowsIcon}>
                        {selectedSuggestionIndex !== 0 && <PressToChangeIcon/>}
                        {!isLastIndex && <PressToChangeIcon/>}
                        {lang === 'en'  ? <p>press to <strong>change</strong></p> : <p>premi per <strong>cambiare</strong></p>}
                    </div> : null}
                    <div className={classes.acceptIcon}>
                        <PressToAcceptIcon/>
                        {lang === 'en'  ? <p>press to <strong>accept</strong></p> : <p>premi per <strong>accettare</strong></p>}
                    </div>
                </div>

            </>
        case 2:
            return <>
                <div>
                    {manualInput?.length > 0 || inputHasFocus ?
                        <div className={classes.acceptIcon}>
                            <PressToGoBackIcon/>
                            {lang === 'en'  ? <p>press to go <strong>back</strong></p> : <p>premi per <strong>tornare indietro</strong></p>}
                        </div> :
                        <div className={classes.acceptIcon}>
                            <PressEditIcon/>
                            {lang === 'en'  ? <p>press to <strong>edit</strong></p> : <p>premi per <strong>editare</strong></p>}
                        </div>
                    }
                </div>
            </>
        case 3:
            return <>
                {manualInput?.length > 0 &&
                    <div className={classes.acceptIcon}>
                        <PressToAcceptIcon/>
                        {lang === 'en'  ? <p>press to <strong>accept</strong></p>  : <p>premi per <strong>accettare</strong></p>}
                    </div>
                }
            </>
        case 4:
            return <>
                <div className={classes.acceptIcon}>
                    <PressToSkipIcon/>
                    {lang === 'en'  ? <p>press to <strong>skip</strong></p> : <p>premi per <strong>marcare come falsa abbreviazione</strong></p>}
                </div>
            </>
    }
}

export default SuggestionsIcons;