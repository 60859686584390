import React from 'react';
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'left',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        border: `1px solid rgb(0 71 154 / 30%)`,
        borderRadius: 8,
        padding: 16,
        gap: 10,
        cursor: 'default',
    },
    title: {
        fontSize: 18,
        fontWeight: 600,
        color: theme.palette.common.dark,
    }
}))


const ItemContainer = ({
                           title,
                           children,
                       }) => {
    const classes = useStyles();

    return <>
        <div
            className={classes.container}
        >
            <h1
                className={classes.title}
            >{title}</h1>
            {children}
        </div>
    </>
}

export default ItemContainer;