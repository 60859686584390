import {createUseStyles} from 'react-jss'
import {useState} from "react";

const useStyles = createUseStyles((theme) => ({
    wrapperInput: {
        width: '400px',
    }
}))


const Search = ({
                    tableData,
                    setTableDataFiltered,
                    tableDataFiltered,
                    setSearchValue,
                    searchValue,
                    placeholder = 'Filtra per titolo lemma...',
                    isOnlyFrontSearch = false,
                    ...props
                }) => {

    const [value, setValue] = useState('')

    const search = event => {
        event.preventDefault()
        if (event.target.value === '') {
            setTableDataFiltered(tableData)
            setSearchValue('')
            setValue('')
        } else {
            setValue(event.target.value)
            setSearchValue(event.target.value)
            isOnlyFrontSearch && setTableDataFiltered(tableData.filter(item => item.abbreviation.toLowerCase().includes(event.target.value.toLowerCase())))
        }
    }


    const classes = useStyles()
    return <>
        <div className={classes.wrapperInput}>
            <input
                value={value || searchValue || ''}
                placeholder={placeholder}
                onChange={event => search(event)}
            />
        </div>
    </>
}

export default Search