import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import PageLayout from "../../containers/page-layout/PageLayout";
import {useNavigate, useParams} from "react-router-dom";
import {ABBREVIATION_MODEL} from "../mockData";
import {EditIcon, PressToChangeIcon} from "../../assets/icons";
import {checkIfIsSup, handleApiError, truncateText} from "../../utilities/helpers";
import Button from "../../components/button/Button";
import {ROUTE_ABBREVIATIONS, ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_LINGUISTS} from "../../utilities/constants";
import AdminAPI from "../../http-requests/admin.http";
import AbbreviationAPI from "../../http-requests/abbreviation.http";
import useAbbreviations from "../../hooks/useAbbreviations";
import {normalize, schema} from "normalizr";
import Spinner from "../../components/spinner/Spinner";
import useError from "../../hooks/useError";

const useStyles = createUseStyles(theme => ({
    context: {
        gridRowStart: 'content',
        gridRowEnd: 'content',
        gridColumnStart: 1,
        gridColumnEnd: 6,
        display: 'flex',
        justifyContent: 'center',
        padding: [10, 0, 16, 0],
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            width: 7,
        },
        '&::-webkit-scrollbar-thumb': {
            height: 40,
            borderRadius: 8,
        },
        '&:hover::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.5)',
            '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
        }
    },

    suggestions: {
        display: 'grid',
        gridTemplateColumns: '1fr 20px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 6,
        maxWidth: 880,
    },
    title: {
        flexGrow: 1,
        fontSize: 22,
        fontWeight: 500,
        textAlign: 'left',
        display: 'grid',
        gridTemplateColumns: 'minmax(100px, auto) auto minmax(400px, auto)',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 16
    },

    icon: {
        transform: 'rotate(270deg)'
    },

    buttonContainer: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        borderTop: `1px solid ${theme.palette.primary.main}`,
        padding: [16, 0, 4]
    },

    button: {
        gridArea: ' 9 / 3 / 10 / 4',
        justifySelf: 'end',
        color: 'rgb(255, 255, 255)',
        width: 220,
        cursor: 'pointer',
        height: 56,
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        borderRadius: 6,
        justifyContent: 'center',
        backgroundColor: 'rgba(34, 104, 189, 0.75)',
        '& span': {
            fontSize: 18,
            fontWeight: 700,
        }
    },

}))


const Batch = () => {
    const {batchID} = useParams();
    const navigate = useNavigate()
    const {abbreviationsSet, setAbbreviations, setIsFetching, isFetching} = useAbbreviations()
    const abbreviationsSchema = new schema.Entity('abbreviations', {}, {idAttribute: 'lemma_id'})
    const [isLoading, setIsLoading] = useState(false)
    const showError = useError()

    const goToAbbreviation = (id) => {
        navigate(`${ROUTE_ABBREVIATIONS}/${id}`)
    }

    useEffect(() => {
        getAdminAbbreviationSet()
    }, [batchID])

    const getAdminAbbreviationSet = async () => {
        try {
            setIsFetching(true)
            const {data} = await AdminAPI.getSummaryBatch(batchID)
            const remapped = data.map(abb => ({
                ...abb,
                id: abb[ABBREVIATION_MODEL.id],
                suggestions: [
                    ...abb[ABBREVIATION_MODEL.tableSuggestions].map(s => ({title: s, priority: 1})),
                    ...abb[ABBREVIATION_MODEL.openAiSuggestions].map(s => ({title: s, priority: 2})),
                    ...abb[ABBREVIATION_MODEL.filenameSuggestions].map(s => ({title: s, priority: 3})),
                ]
            }))
            const {entities, result: allIds} = normalize(remapped, [abbreviationsSchema])
            setAbbreviations({allIds, byId: entities[abbreviationsSchema.key]})

        } catch (err) {
            handleApiError(err, {handleGeneralError: showError})
        } finally {
            setIsFetching(false)
        }
    }

    const complete = async () => {
        try {
            setIsLoading(true)
            await AbbreviationAPI.finalConfirmAbbreviation(batchID)
            await AbbreviationAPI.finalizeBatch()
            navigate(ROUTE_DASHBOARD)
            window.location.reload()
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }

    const classes = useStyles();

    return <>
        {isFetching || isLoading && <Spinner/>}
        <PageLayout isNotScrollable pageTitle={`#${batchID} - Batch Summary`}>
            <div className={classes.context}>
                <div className={classes.suggestions}>
                    {abbreviationsSet.allIds?.map((abbrId) => {
                        const abbreviation = abbreviationsSet.byId[abbrId]
                        return <>
                            <div>
                                <div className={classes.title}>
                                    <span>{checkIfIsSup(abbreviation[ABBREVIATION_MODEL.title])}</span>
                                    <div className={classes.icon}>
                                        <PressToChangeIcon
                                            isOnlyArrow={false}
                                        />
                                    </div>
                                    <span>
                                    {truncateText(abbreviation[ABBREVIATION_MODEL.extension]?.replace(/<\/?[^>]+(>|$)/g, ""))}
                                </span>
                                </div>
                            </div>
                            <EditIcon
                                onClick={() => goToAbbreviation(abbreviation[ABBREVIATION_MODEL.id])}
                                style={{
                                    cursor: 'pointer',
                                    visibility: !!abbreviation[ABBREVIATION_MODEL.extension] || !!abbreviation.false_abbreviation ? 'visible' : 'hidden'
                                }}
                            />
                        </>
                    })}
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    onClick={complete}
                    data={'primary'}
                    style={{justifySelf: 'center'}}
                    className={classes.button}
                >Complete Review</Button>
            </div>
        </PageLayout>
    </>
}

export default Batch;