export const colors = {
    common: {
        black: '#000',
        white: '#fff',
        offBlack: '#14142B',
    },
    primary: {
        100: '#EFE9FC',
        200: '#CFBCF6',
        300: '#A581EE',
        400: '#00479A',
        500: '#6426E1',
        600: '#501ABC',
        700: '#3D148F',
        800: '#2A0E63',
        900: '#170736',
        A05: 'rgba(100, 38, 225, 0.05)',
    },
    secondary: {
        100: '#E6F9F6',
        200: '#BDF0E8',
        300: '#93E6DA',
        400: '#6ADCCB',
        500: '#41D3BD',
        600: '#2AB6A1',
        700: '#218D7C',
        800: '#176358',
        900: '#0D3A33',
    },
    tertiary: {
        100: '#F7DAEA',
        200: '#F0B8DC',
        300: '#D289BF',
        400: '#A65D99',
        500: '#6B2D66',
        600: '#5B205C',
        700: '#47164D',
        800: '#340E3E',
        900: '#270833',
        A90: 'rgba(107, 45, 102, 0.9)',
        A75: 'rgba(107, 45, 102, 0.75)',
        A50: 'rgba(107, 45, 102, 0.5)',
        A35: 'rgba(107, 45, 102, 0.35)',
        A15: 'rgba(107, 45, 102, 0.15)',
        A05: 'rgba(121, 65, 117, 0.05)',
    },
    quaternary: {
        100: '#FDDEDA',
        200: '#FCB7B6',
        300: '#F89098',
        400: '#F17288',
        500: '#E84672',
        600: '#C73369',
        700: '#A72360',
        800: '#861655',
        900: '#6F0D4E',
        A90: 'rgba(232, 70, 114, 0.9)',
        A75: 'rgba(232, 70, 114, 0.75)',
        A50: 'rgba(232, 70, 114, 0.5)',
        A35: 'rgba(232, 70, 114, 0.35)',
        A15: 'rgba(232, 70, 114, 0.15)',
        A05: 'rgba(233, 88, 128, 0.05)',
    },
    quinary: {
        100: '#FFF3E5',
        200: '#FFD099',
        300: '#FFB966',
        400: '#FFA133',
        500: '#FF8A00',
        600: '#CC6E00',
        700: '#995300',
        800: '#663700',
        900: '#331C00',
    },
    success: {
        100: '#CFFAD1',
        200: '#A1F6AD',
        300: '#7DE896',
        400: '#60D286',
        500: '#20DF73',
        600: '#15B76B',
        700: '#0E8B5D',
        800: '#075F47',
        900: '#023129',
        101: '#76B90052',
        102: '#76B900',
        103: '#2FB177'
    },
    error: {
        100: '#FEEBE7',
        200: '#FBA39D',
        300: '#F57074',
        400: '#EB4C60',
        500: '#DF1642',
        600: '#BF1047',
        700: '#A00B48',
        800: '#810744',
        900: '#6B0442',
        A75: 'rgba(253, 215, 207, 0.25)',
    },
    yellow: {
        100: '#FFF6D7',
        200: '#FFECAF',
        300: '#FFDE87',
        400: '#FFD169',
        500: '#FFBB38',
        600: '#DB9828',
        700: '#B7771C',
        800: '#935911',
        900: '#7A440A',
    },
    greyscale: {
        A100: '#F7F7F7',
        A200: '#CCD4DE',
        A300: '#B2BECA',
        A400: '#CCD4DE3D',
        A500: '#30303080',
        A600: '#D9D9D9',
        50: '#FCFCFC',
        100: '#FAFAFA',
        150: '#F9F9F9',
        200: '#F3F3F3',
        300: '#E1E1E1',
        400: '#AFAFAF',
        500: '#808080',
        600: '#595959',
        700: '#2E2E2E',
        800: '#1F1F1F',
    },
    black: {
        800: '#343A40',
    },
    transparency: {
        light: {
            A95: 'rgba(252, 252, 252, 0.95)',
            A75: 'rgba(252, 252, 252, 0.75)',
            A65: 'rgba(252, 252, 252, 0.65)',
            A40: 'rgba(252, 252, 252, 0.4)',
            A25: 'rgba(252, 252, 252, 0.25)',
            A10: 'rgba(252, 252, 252, 0.1)',
            A05: 'rgba(252, 252, 252, 0.05)',
        },
        dark: {
            A95: 'rgba(31, 31, 31, 0.95)',
            A75: 'rgba(31, 31, 31, 0.75)',
            A65: 'rgba(31, 31, 31, 0.65)',
            A40: 'rgba(31, 31, 31, 0.4)',
            A25: 'rgba(31, 31, 31, 0.25)',
            A10: 'rgba(31, 31, 31, 0.1)',
        },
        red: {
            R1: 'rgba(233, 88, 128, 0.05)',
        },
    },
    backgrounds: {
        primary: 'rgba(0, 71, 154, 0.30)',
        tertiary: '#FBF9FE',
        quaternary: '#F9F4EF',
        success: '#F3FEF3',
        error: '#FEF5F3',
        warning: '#FFFDF5',
        blue: '#6690CE'
    },
    gradients: {
        1:
            'linear-gradient(140deg, #7a41ff, transparent 25%),' +
            'linear-gradient(170deg, #2ad5b8, transparent 45%),' +
            'linear-gradient(190deg, rgba(255, 138, 0, 0.5), transparent 50%),' +
            'linear-gradient(150deg, #8555E7, transparent 50%)',
        2: 'linear-gradient(90deg, #8555E7 -31.62%, #41D3BD 20.57%, #73C08B 31.51%, #FF8A00 61.81%, #E84672 100.53%)',
        3: 'linear-gradient(135deg, #8555E7 29.59%, #41D3BD 100%);',
    },
}

export default colors
