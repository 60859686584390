import {setAlertMessage} from '../store/slices/app'
import store from '../store'
import {FAILURE} from './constants/'
import React from "react";

const transformCheckboxNumberValue = (e) => {
    const output = parseInt(e.target.value, 10)
    return isNaN(output) ? 0 : output
}

export const dispatchErrorMessage = (message) => {
    store.dispatch(
        setAlertMessage({message, visible: true, variant: FAILURE})
    )
}

export const handleApiError = (
    error,
    {
        handleGeneralError = console.log,
        handleFormError = console.log,
    }) => {
    const {response: {data} = {}} = error
    Object.keys(data).forEach(errorFieldName => {
        const rawErrorValue = data[errorFieldName]
        if (errorFieldName === 'message') {
            // Generic error
            handleGeneralError(rawErrorValue)
        } else {
            // Form field error
            const message = Array.isArray(rawErrorValue)
                ? rawErrorValue.join(' ')
                : rawErrorValue
            handleFormError(errorFieldName, {message})
        }
    })
}
export const capitalize = ([firstLetter, ...rest]) =>
    firstLetter.toUpperCase() + rest.join('')

export const saferun = (callback, params) => {
    if (typeof callback === 'function') {
        params ? callback(params) : callback()
    }
}

export const truncateText = (text, length = 15) => {
    if (text?.length > length) {
        return text.substring(0, length) + '...'
    }
    return text
}

export const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    if (days > 0) {
        return `${days}d ${hours % 24}h`
    } else if (hours > 0) {
        return `${hours}h ${minutes % 60}m`
    } else {
        const seconds = Math.round(time % 60)
        return `${minutes}m ${seconds}s`
    }

}


export const checkIfIsSup = (title) => {
    const isSup = title?.includes('<sup' || '</sup>' || '<sup>')
    const isCardinal = title?.match(/\d+/g)
    const cardinalNumber = title?.match(/\d+[a-z]/g)
    const isItalic = title?.includes('</i>' || '<i>' || '<i')

    switch (true) {
        case isSup:
            return <span dangerouslySetInnerHTML={{__html: title}}/>
        case cardinalNumber:
            const supTag = `<p>${isCardinal}<sup>°</sup></p>`
            return <div dangerouslySetInnerHTML={{__html: supTag}}/>
        case isItalic:
            const replaceTag = title?.replace(/<\/i>/g, '').replace(/<i>/g, '')
            return  <span dangerouslySetInnerHTML={{__html: replaceTag}}/>
        default:
            return title
    }
}
