import {createUseStyles} from "react-jss";
import {Link} from "react-router-dom";
import {ROUTE_HOME} from "../../utilities/constants";
import PageLayout from "../../containers/page-layout/PageLayout";

const useStyles = createUseStyles(theme => ({
    link: {
        cursor: 'pointer'
    }
}))


const NotFound = () => {
    const classes = useStyles();

    return <PageLayout pageTitle={'404 - Not found'}>
        <div className={classes.link}><Link to={ROUTE_HOME}>Go Home</Link></div>
    </PageLayout>
}

export default NotFound;