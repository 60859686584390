import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import PageLayout from "../../containers/page-layout/PageLayout";
import ItemContainer from "../../components/item-dashboard/ItemContainer";
import Table from "../../components/table/Table";
import {columnsTableDashboard} from "./columns-table-dashboard/columnsTableDashboard";
import AdminAPI from "../../http-requests/admin.http";
import modelStats from "../../models/modelStats";
import Spinner from "../../components/spinner/Spinner";
import {handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";

const useStyles = createUseStyles(theme => ({
    containerItems: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: 'auto',
        gap: 16,
        padding: [16, 0, 0, 0],
        minWidth: 880,
    }
}))


const Dashboard = () => {
    const [completeStats, setCompleteStats] = useState(null);
    const [linguistStats, setLinguistStats] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const showError = useError()

    useEffect(() => {
        getDashboardData();
    }, [])

    const getDashboardData = async () => {
        try {
            setIsLoading(true);
            const {data} = await AdminAPI.getStats();
            setCompleteStats(modelStats(data.complete_stats));
            setLinguistStats(data.linguists_stats);
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false);
        }
    }


    const classes = useStyles();
    return <>
        {isLoading && <Spinner/>}
        <PageLayout
            pageTitle={'Dashboard'}
        >
            <div
                className={classes.containerItems}
            >
                {completeStats?.map(item => (
                    <ItemContainer
                        key={item.id}
                        title={item.title}
                    >
                        {item.value}
                    </ItemContainer>
                ))}
            </div>


            <Table
                isClickable={false}
                columns={columnsTableDashboard}
                data={linguistStats}
            />
        </PageLayout>

    </>
}

export default Dashboard;