export const ABBREVIATION_MODEL = {
    title: 'abbreviation',
    documentName: 'title',
    context: 'context',
    openAiSuggestions: 'openai_suggestion',
    tableSuggestions: 'suggestions',
    filenameSuggestions: 'filename_suggestion', // ['Vivaldi, Antonio'],
    status: 'status',
    id: 'lemma_id',
    section: 'section_id',
    extension: 'extended_abbreviation',
    falseAbbreviation: 'false_abbreviation'
}

const open_ai_suggestions = [
    {id: 2, title: 'professare'},
    {id: 3, title: 'professoressa'},
    {id: 5, title: 'profano'},
]
const table_suggestions = [
    {id: 1, title: 'professore'},
]
const filename_suggestions = [
    {id: 4, title: 'profondità'},
]

export const mockAbbreviations = [
    {
        [ABBREVIATION_MODEL.id]: 1,
        [ABBREVIATION_MODEL.documentName]: 'titolo del primo documento',
        [ABBREVIATION_MODEL.title]: 'prof.',
        [ABBREVIATION_MODEL.context]: 'Halle 1833), nipote di Christian Konrad; dal 1795 prof. ordinario di patologia generale a Halle. Stu',
        [ABBREVIATION_MODEL.openAiSuggestions]: open_ai_suggestions,
        [ABBREVIATION_MODEL.tableSuggestions]: table_suggestions,
        [ABBREVIATION_MODEL.filenameSuggestions]: filename_suggestions,
        [ABBREVIATION_MODEL.status]: 'completed',
    },
    {
        [ABBREVIATION_MODEL.id]: 2,
        [ABBREVIATION_MODEL.documentName]: 'titolo del secondo documento',
        [ABBREVIATION_MODEL.title]: 'V.',
        [ABBREVIATION_MODEL.context]: 'c. a.C. Fedeli durante la seconda guerra punica, i V. presero parte alla guerra sociale contro Roma e\n',
        [ABBREVIATION_MODEL.openAiSuggestions]: [
            {id: 7, title: 'verbo'},
            {id: 8, title: 'vedi'},
        ],
        [ABBREVIATION_MODEL.tableSuggestions]: [],
        [ABBREVIATION_MODEL.filenameSuggestions]: [
            {id: 6, title: 'Vestini'},
        ],
        [ABBREVIATION_MODEL.status]: 'pending',

    },
    {
        [ABBREVIATION_MODEL.id]: 3,
        [ABBREVIATION_MODEL.documentName]: 'titolo del 3 documento',
        [ABBREVIATION_MODEL.title]: 'trad. it',
        [ABBREVIATION_MODEL.context]: 'Halle 1833), nipote di Christian Konrad; dal 1795 prof. ordinario di patologia generale a Halle. Stu',
        [ABBREVIATION_MODEL.openAiSuggestions]: open_ai_suggestions,
        [ABBREVIATION_MODEL.tableSuggestions]: table_suggestions,
        [ABBREVIATION_MODEL.filenameSuggestions]: filename_suggestions,
        [ABBREVIATION_MODEL.status]: 'pending',
    },
]