import {createUseStyles} from 'react-jss'
import cx from 'classnames'
import {useState} from "react";

const useStyles = createUseStyles((theme) => ({
    tooltipContainer: {
        display: 'inline-block',
        position: ({position}) => position && position,
    },
    tooltipContent: {
        display: 'block',
        position: 'absolute',
        borderRadius: 4,
        left: '10%',
        top: ({top}) => top && top,
        transform: 'translateX(-10%)',
        padding: 6,
        fontSize: 14,
        zIndex: 100,
        whiteSpace: 'wrap',
        backgroundColor: 'black',
        color: 'white',
        minWidth: 165,
        textAlign: 'center',
        '&::after': {
            content: `''`,
            position: 'absolute',
            top: '100%',
            left: 20,
            width: 0,
            height: 0,
            borderTop: `8px solid black`,
            borderLeft: `8px solid transparent`,
            borderRight: `8px solid transparent`
        },
    },
}))

const Tooltip = ({
                     visibilityCondition,
                     children,
                     content,
                     classNames,
                     top = '-90%',
                     position = 'relative',
                 }) => {

    let timeout;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, 400);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    // TODO: ADD DIRECTION AND POSITION
    const classes = useStyles({visibilityCondition, top, position});
    return (
        <>
            <div
                className={cx(classes.tooltipContainer, classNames)}
                onMouseEnter={showTip}
                onMouseLeave={hideTip}
            >
                {children}
                {active && (
                    <div className={classes.tooltipContent}>
                        {content}
                    </div>
                )}
            </div>
        </>
    )
}

export default Tooltip
