import React, {useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import PageLayout from "../../containers/page-layout/PageLayout";
import Table from "../../components/table/Table";
import {columnsTableLinguists} from "./columns-table-linguists/columnsTableLinguists";
import {useNavigate} from "react-router-dom";
import AdminAPI from "../../http-requests/admin.http";
import Spinner from "../../components/spinner/Spinner";
import {handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";

const useStyles = createUseStyles(theme => ({
    notFound: {
        display: 'flex',
        opacity: 0.5,
        width: 320,
        height: 48,
        borderLeftWidth: 0,
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgb(0, 0, 0)',
        fontWeight: 700,
        fontSize: 16,
    }
}))


const Linguists = () => {
    const navigate = useNavigate()
    const [linguists, setLinguists] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const showError = useError()

    const navigateToBatch = (id) => {
        navigate(`${id}`)
    }

    useEffect(() => {
        getLinguists()
    }, [])

    const getLinguists = async () => {
        try {
            setIsLoading(true)
            const {data} = await AdminAPI.getLinguists()
            setLinguists(data)
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }

    const classes = useStyles();

    return <>
        {isLoading && <Spinner/>}
        <PageLayout
            pageTitle={'Review Linguists'}
        >

            {!!linguists?.length ?  <Table
                onClickRow={(id) => navigateToBatch(id)}
                columns={columnsTableLinguists}
                data={linguists}
            /> :  <div className={classes.notFound}>No linguists to review</div>}
        </PageLayout>

    </>
}

export default Linguists;