import React, {useCallback, useEffect, useState} from 'react';
import {createUseStyles} from "react-jss";
import PageLayout from "../../containers/page-layout/PageLayout";
import Button from "../../components/button/Button";
import AbbreviationAPI from "../../http-requests/abbreviation.http";
import {useNavigate} from "react-router-dom";
import {ROUTE_HOME} from "../../utilities/constants";
import useUser from "../../hooks/useUser";
import {HideIcon, ViewIcon} from "../../assets/icons";
import {handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";

const useStyles = createUseStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 16,
        padding: 20
    },

    error: {
        backgroundColor: 'red',
        color: 'white',
        padding: '0px 40px',
        borderRadius: 6,
        height: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    wrapper: {
        position: 'relative',
    },

    icon: {
        width: '1.5rem',
        padding: 4,
        position: 'absolute',
        boxSizing: 'border-box',
        top: '50%',
        right: 10,
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
    },

}))


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isUnauthorized, setIsUnauthorized] = useState(false);
    const navigate = useNavigate();
    const {setAccessToken, createUser} = useUser()
    const [showPassword, setShowPassword] = useState(false);
    const showError = useError();

    const loginHandler = async () => {
        try {
            const dataToSend = {
                username,
                password
            }
            const dataToLocalStorage = (`${username}:${password}`);
            setAccessToken(btoa(dataToLocalStorage));
            const data = await AbbreviationAPI.login(dataToSend);
            createUser(data.data);
            navigate(ROUTE_HOME)
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
            setIsUnauthorized(true);
            setTimeout(() => {
                setIsUnauthorized(false);
            }, 2000)
        }
    }

    const onKeyDownHandler = useCallback(
        (e) => {
            switch (e.keyCode) {
                // if enter is pressed
                case 13:
                    loginHandler();
                    break;
            }
        },
        [isUnauthorized, username, password]
    )


    useEffect(
        () => {
            document.addEventListener('keydown', onKeyDownHandler)
            return () => {
                document.removeEventListener('keydown', onKeyDownHandler)
            }
        },
        [onKeyDownHandler]
    )


    const classes = useStyles();

    return <>
        <PageLayout
            pageTitle={'Login'}
        >
            <div className={classes.root}>
                {isUnauthorized && <span
                    className={classes.error}
                >Invalid credentials</span>}
                <input
                    onChange={(e) => setUsername(e.target.value)}
                    type={'text'}
                    placeholder={'Username'}
                />

                <div className={classes.wrapper}>
                    <div className={classes.icon} onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <ViewIcon/> : <HideIcon/>}
                    </div>
                    <input
                        onChange={(e) => setPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        placeholder={'Password'}
                    />
                </div>
                <Button
                    data={'primary'}
                    disabled={username.length === 0 || password.length === 0}
                    onClick={() => loginHandler()}
                >Login</Button>

            </div>


        </PageLayout>

    </>
}

export default Login;