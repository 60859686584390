export const columnsTableLinguists = [
    {
        id: 1,
        title: 'ID',
        keyName: 'id',
    },
    {
        id: 2,
        title: 'Name',
        keyName: 'username',
        width: 300,
    },
    {
        id: 3,
        title: 'Completed Batches',
        keyName: 'batches',
    },
    {
        id: 6,
        title: '',
        keyName: 'goToLinguist',
    },
]
