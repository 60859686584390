import {createUseStyles} from 'react-jss'
import {Outlet} from "react-router-dom";
import Header from "../../components/header/Header";
import {toggleAlert} from "../../store/slices/app";
import {useDispatch} from "react-redux";
import Alert from "../../components/alert/Alert";
import useAlert from "../../hooks/useAlert";


const useStyles = createUseStyles((theme) => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5, 1fr)',
        gridTemplateRows: ' 64px 0.02fr 30px 30px 1fr 60px',
        gridTemplateAreas: `
            "header header header header header"
            "title title title title title"
            "context1 context1 context1 context1 context1"
            "context2 context2 context2 context2 context2"
            "content content content content content"
            "footer footer footer footer footer"
        `,
        height: '100%',
        [theme.mediaQueries.xxlUp]: {
            gridTemplateRows: ' 96px 0.04fr 48px 48px 1fr 60px',
        }
    },
    alerts: {
        pointerEvents: 'none',
        position: 'fixed',
        top: 68,
        left: 8,
        right: 8,
        zIndex: 1029,
        [theme.mediaQueries.mUp]: {
            top: 132,
        },
    },

    row: {
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 6,
    },
    blue: {
        extend: 'row',
        gridRowStart: 'header',
        gridRowEnd: 'context1',
        backgroundColor: theme.palette.background.section,
        minHeight: 170,
        [theme.mediaQueries.xxlUp]: {
            minHeight: 320,
        }
    },
    white: {
        extend: 'row',
        gridRowStart: 'context2',
        gridRowEnd: 'footer',
        backgroundColor: theme.palette.common.light,
    },
}))


export const ABBREVIATION_MODEL = {
    title: 'abbreviation',
    documentName: 'title',
    context: 'context',
    openAiSuggestions: 'openai_suggestions',
    tableSuggestions: 'suggestions',
    filenameSuggestions: 'filename_suggestions', // ['Vivaldi, Antonio'],
    status: 'status',
    id: 'lemma_id',
    section: 'section_id'
}


const PublicLayout = () => {

    const dispatch = useDispatch()
    const {isAlertOpen, alertData} = useAlert()


    const classes = useStyles()

    return <>
        <div className={classes.alerts}>
            {isAlertOpen && (
                <Alert
                    visible={isAlertOpen}
                    variant={alertData.severity}
                    message={alertData.title}
                    dismissTimeout={5000}
                    dismissCallback={() =>
                        dispatch(toggleAlert({visible: false}))
                    }
                />
            )}
        </div>
        <div className={classes.gridContainer}>
            <div className={classes.blue}/>
            <Header/>
            <div className={classes.white}/>
                <Outlet/>
            </div>
    </>
}

export default PublicLayout
