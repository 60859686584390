// Authentication
export const ROUTE_NOT_FOUND = '/404'
export const ROUTE_LOGIN = '/login'

// linguist
export const ROUTE_HOME = '/'
export const ROUTE_ABBREVIATIONS = '/abbreviations'
export const ROUTE_ABBREVIATIONS_EDIT = ':abbreviationID'

// admin
export const ROUTE_DASHBOARD = '/dashboard'
export const ROUTE_LINGUISTS = '/linguists'
export const ROUTE_LINGUIST = ':linguistID'
export const ROUTE_BATCH_ID =  ':batchID'

//QA
export const ROUTE_REVIEW = '/review'
export const ROUTE_SUMMARY_REVIEW = ':reviewID'
export const ROUTE_EDIT_REVIEW = 'edit-lemmas/:lemmaID'