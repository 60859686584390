import React, {createContext, useCallback, useEffect, useReducer, useRef, useState} from 'react'
import {ABBREVIATION_MODEL} from "../pages/mockData";
import { normalize, schema } from 'normalizr'
import AbbreviationAPI from "../http-requests/abbreviation.http";
import {handleApiError} from "../utilities/helpers";
import useError from "../hooks/useError";
import useUser from "../hooks/useUser";

export const AbbreviationsContext = createContext(null)
const abbreviationsSchema = new schema.Entity('abbreviations', {}, {idAttribute: 'lemma_id'})

const SET_ABBREVIATIONS_DATA_ACTION = 'SET_ABBREVIATIONS_DATA'
const UPDATE_ABBREVIATION_DATA_ACTION = 'UPDATE_ABBREVIATION_DATA'

const abbreviationsReducer = (state, action) => {
    switch (action.type) {
        case SET_ABBREVIATIONS_DATA_ACTION:
            return action.payload
        case UPDATE_ABBREVIATION_DATA_ACTION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload.data
                }
            }
        default:
            return state
    }
}

const AbbreviationsProvider = ({children}) => {
    const initialState = {
        byId: {},
        allIds: []
    }
    const [abbreviations, dispatch] = useReducer(abbreviationsReducer, initialState)
    const [isFetching, setIsFetching] = useState(true)
    const showError = useError()
    const {user, accessToken} = useUser()

    const setAbbreviations = useCallback(payload => {
        dispatch({ type: SET_ABBREVIATIONS_DATA_ACTION, payload })
    }, [])


    const updateAbbreviation = useCallback((payload) => {
        dispatch({ type: UPDATE_ABBREVIATION_DATA_ACTION, payload })
    }, [])

    const isFetchingRef = useRef(false)
    const getAbbreviationSet = async () => {
        try {
            isFetchingRef.current = true
            setIsFetching(true)
            const {data} =  await AbbreviationAPI.getAbbreviationSet()
            const remapped = data.map(abb => ({
                ...abb,
                id: abb[ABBREVIATION_MODEL.id],
                suggestions: [
                    ...abb[ABBREVIATION_MODEL.tableSuggestions].map(s => ({title: s, priority: 1})),
                    ...abb[ABBREVIATION_MODEL.openAiSuggestions].map(s => ({title: s, priority: 2})),
                    ...abb[ABBREVIATION_MODEL.filenameSuggestions].map(s => ({title: s, priority: 3})),
                ]
            }))
            const { entities, result: allIds } = normalize(remapped, [abbreviationsSchema])
            setAbbreviations({allIds, byId: entities[abbreviationsSchema.key]})

        } catch (err) {
            handleApiError(err, {handleGeneralError: showError})
        }finally {
            isFetchingRef.current = false
            setIsFetching(false)
        }
    }
    
    useEffect(
        () => {
            if (user) {
                !user?.is_admin &&
                user?.is_qa === 0 &&
                !isFetchingRef.current &&
                !abbreviations.allIds.length &&
                getAbbreviationSet();
            }
        },
        [user]
    )

    return (
        <AbbreviationsContext.Provider
            value={{
                abbreviationsSet: abbreviations,
                setAbbreviations,
                updateAbbreviation,
                isFetching,
                setIsFetching
            }}
        >
            {children}
        </AbbreviationsContext.Provider>
    )
}

export default AbbreviationsProvider
