import React, {useEffect, useState} from 'react';
import Table from "../../components/table/Table";
import {columnsReview} from "./columnsReview";
import {handleApiError} from "../../utilities/helpers";
import useError from "../../hooks/useError";
import QaAPI from "../../http-requests/qa.http";
import Spinner from "../../components/spinner/Spinner";
import PageLayout from "../../containers/page-layout/PageLayout";
import {useLocation, useNavigate} from "react-router-dom";
import Search from "../../components/search/Search";
import {ROUTE_REVIEW} from "../../utilities/constants";

const Review = () => {
    const [documents, setDocuments] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const showError = useError()
    const navigate = useNavigate()
    const [tableDataFiltered, setTableDataFiltered] = useState(null)
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const {state} = useLocation();

    useEffect(() => {
        if (state?.page) {
            getDocumentsData(state.page);
        } else {
            getDocumentsData();
        }

        if (state?.searchValue) {
            setSearchValue(state.searchValue)
        }
        window.onbeforeunload = () => {
            navigate(ROUTE_REVIEW, { state: { page: null, searchValue: null } });
        };

        return () => {
            window.onbeforeunload = null;
        };
    }, [state]);

    useEffect(() => {
        let timeoutId = null;
        const handleAction = () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                handleSearch();
            }, 500);
        };
        handleAction();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [searchValue]);


    const getDocumentsData = async (page) => {
        setIsLoading(true)
        try {
            const {data} = await QaAPI.getDocuments({
                page: page ? page : null,
                q: searchValue,
            });
            setPage(page)
            setDocuments(data?.data)
            setTableDataFiltered(data?.data)
            setTotalPages(data?.total_pages)
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }


    const handleSearch = async () => {
        setIsLoading(true)
        try {
          if (searchValue !== null || state.searchValue !== null){
              const {data} = await QaAPI.getDocuments({
                  q: searchValue,
              });
              setPage(1)
              setDocuments(data?.data)
              setTableDataFiltered(data?.data)
              setTotalPages(data?.total_pages)
          }
        } catch (e) {
            handleApiError(e, {handleGeneralError: showError})
        } finally {
            setIsLoading(false)
        }
    }

    return <>
        {isLoading && <Spinner/>}
        <PageLayout
            pageTitle={'Lemmi'}
        >

            <Search
                tableData={documents}
                setTableDataFiltered={setTableDataFiltered}
                tableDataFiltered={tableDataFiltered}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
            />

            <Table
                data={tableDataFiltered}
                columns={columnsReview}
                isPaginated
                getDocumentsData={getDocumentsData}
                page={page}
                onClickRow={(id) => navigate(`/review/${id}`, {state: {page: page, searchValue: searchValue ? searchValue : null}})}
                totalPages={totalPages}
            />
        </PageLayout>
    </>
}

export default Review;